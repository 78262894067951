import Box from "@mui/material/Box"
import { CircularProgress, Stack, Typography } from '@mui/material';

const LoadingSpinner = () => {
    return (
        <Box
            sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: { xs: 2, sm: 4 }
            }}
        >
            <Stack justifyContent="center" alignItems="center" spacing={2}>
                <CircularProgress />
                <Typography variant="h6">Cargando...</Typography>
            </Stack>
        </Box>
    )
}

export default LoadingSpinner;