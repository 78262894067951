import { Create } from "react-admin";
import RolForm from "./RolForm";

const RolCreate = () => (
    <Create>
        <RolForm title="crear rol"/>
    </Create>
);

export default RolCreate;
