import buildRestProvider from "../shared/utilities/simpleRestProvider"

import {
  CreateParams,
  UpdateParams,
  DataProvider,
  fetchUtils,
} from "react-admin";
import { WeightReportOperation } from "../shared/types/types";

const fetchJson = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("X-Correlation-Id", "foobar");
  options.headers.set("X-App-Id", "00e9396e-6c63-4834-8492-a1db4edc6421");
  options.headers.set(
    "Authorization",
    `Bearer ${localStorage.getItem("auth")?.toString()}`
  );
  return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = buildRestProvider(
  String(process.env.REACT_APP_API_URL),
  fetchJson
);

const createPostFormData = async (
  params:
    | CreateParams<WeightReportOperation>
    | UpdateParams<WeightReportOperation>
) => {
  const formData = new FormData();

  const weightReportOperation = params.data;

  if (weightReportOperation.verificationListFile) {
    if (weightReportOperation.verificationListFile.rawFile) {
      formData.append(
        "verificationListFile",
        weightReportOperation.verificationListFile.rawFile!
      );
    } else {
      const res = await fetch(weightReportOperation.verificationListFile.src!);
      const data = await res.blob();
      formData.append(
        "verificationListFile",
        new File([data], weightReportOperation.verificationListFile.title!, {
          type: res.headers.get("content-type")!,
        })
      );
    }
  }

  if (weightReportOperation.dusCopy) {
    if (weightReportOperation.dusCopy.rawFile) {
      formData.append("dusCopy", weightReportOperation.dusCopy.rawFile!);
    } else {
      const res = await fetch(weightReportOperation.dusCopy.src!);
      const data = await res.blob();
      formData.append(
        "dusCopy",
        new File([data], weightReportOperation.dusCopy.title!, {
          type: res.headers.get("content-type")!,
        })
      );
    }
  }

  if (weightReportOperation.customDeliveryLetter) {
    if (weightReportOperation.customDeliveryLetter.rawFile) {
      formData.append(
        "customDeliveryLetter",
        weightReportOperation.customDeliveryLetter.rawFile!
      );
    } else {
      const res = await fetch(weightReportOperation.customDeliveryLetter.src!);
      const data = await res.blob();
      formData.append(
        "customDeliveryLetter",
        new File([data], weightReportOperation.customDeliveryLetter.title!, {
          type: res.headers.get("content-type")!,
        })
      );
    }
  }

  if (weightReportOperation.weightReport?.file) {
    if (weightReportOperation.weightReport?.file.rawFile) {
      formData.append(
        "weightReportFile",
        weightReportOperation.weightReport?.file.rawFile!
      );
    } else {
      const res = await fetch(weightReportOperation.weightReport?.file.src!);
      const data = await res.blob();
      formData.append(
        "weightReportFile",
        new File([data], weightReportOperation.weightReport?.file.title!, {
          type: res.headers.get("content-type")!,
        })
      );
    }
  }

  const otherFiles = weightReportOperation.otherFiles;
  if (otherFiles) {
    for (let i = 0; i < otherFiles.length; i++) {
      if (otherFiles[i]) {
        if (otherFiles[i].rawFile) {
          formData.append(
            "otherFiles",
            otherFiles[i].rawFile!
          );
        } else {
          const res = await fetch(
            otherFiles[i].src!
          );
          const data = await res.blob();
          formData.append(
            "otherFiles",
            new File(
              [data],
              otherFiles[i].title!,
              { type: res.headers.get("content-type")! }
            )
          );
        }
      }
    }
  }

  formData.append(
    "weightReportOperation",
    new Blob([JSON.stringify(weightReportOperation)], {
      type: "application/json",
    })
  );
  return formData;
};

export const dataProvider: DataProvider = {
  ...baseDataProvider,
  create: async (resource, params) => {
    if (resource === "weightReportOperation") {
      const formData = await createPostFormData(params);
      return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({ data: json }));
    }

    return baseDataProvider.create(resource, params);
  },
  update: async (resource, params) => {
    if (resource === "weightReportOperation") {
      const formData = await createPostFormData(params);
      return fetchJson(
        `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
        {
          method: "PUT",
          body: formData,
        }
      ).then(({ json }) => ({ data: json }));
    }

    return baseDataProvider.update(resource, params);
  },
};
