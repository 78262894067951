import { AppBar, Box, Toolbar as MuiToolbar } from "@mui/material";
import { Toolbar, SaveButton, DeleteButton, DeleteWithConfirmButton } from "react-admin";

interface FormToolbarProps {
  edit?: boolean;
}

export default function FormToolbar({ edit }: FormToolbarProps) {
  return (
    <>
      <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
          <Toolbar sx={{display: "flex", justifyContent: "end",/*  border: "solid blue" */ paddingRight: "10px"}}>
            <SaveButton alwaysEnable />
            {edit && <DeleteWithConfirmButton size="medium" sx={{ml: 1, color: "text.primary"}}/>}
          </Toolbar>
      </AppBar>
      <MuiToolbar />
    </>
  );
}
