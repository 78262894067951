const periods = [
  {
    id: 1,
    name: "ANUAL"
  },
  {
    id: 2,
    name: "BIENAL"
  },
  {
    id: 3,
    name: "TRIENAL"
  }
]

export default periods;
