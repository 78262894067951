import { Create, TabbedForm } from "react-admin"
import LaboratoryForm from "./LaboratoryForm"

export default function LaboratoryCreate() {
  return(
    <Create>
      <LaboratoryForm />
    </Create>
  )
}
