import ProductIcon from '@mui/icons-material/Collections';
import LaboratoryRegistryList from './LaboratoryRegistryList';
import LaboratoryRegistryCreate from './LaboratoryRegistryCreate';
import LaboratoryRegistryEdit from './LaboratoryRegistryEdit';

export default {
    list: LaboratoryRegistryList,
    create: LaboratoryRegistryCreate,
    edit: LaboratoryRegistryEdit,
    icon: ProductIcon,
};
