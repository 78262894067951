export default
    [
        { id: 'AP', label: "Arica y Parinacota" },
        { id: 'TA', label: "Tarapacá" },
        { id: 'AN', label: "Antofagasta" },
        { id: 'AT', label: "Atacama" },
        { id: 'CO', label: "Coquimbo" },
        { id: 'VA', label: "Valparaíso" },
        { id: 'RM', label: "Metropolitana de Santiago" },
        { id: 'LI', label: "O'Higgins" },
        { id: 'ML', label: "Maule" },
        { id: 'NB', label: "Ñuble" },
        { id: 'BI', label: "Biobío" },
        { id: 'AR', label: "La Araucanía" },
        { id: 'LR', label: "Los Ríos" },
        { id: 'LL', label: "Los Lagos" },
        { id: 'AI', label: "Aysén del General Carlos Ibáñez del Campo" },
        { id: 'MA', label: "Magallanes y de la Antártica Chilena" },
    ];
