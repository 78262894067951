import { Button, useRecordContext } from 'react-admin';
import sacopModules from '../../utilities/sacopModules';
import { Sacop } from '../../types/types';


const isActionButtonDisabled = (record: any, action: string) => {
    switch (action) {
        case sacopModules.REQUESTFORACTION:
            return record.sendToResponsibleForAnalysis;
        case sacopModules.ANALYSISFORSHARES:
            return record.sendToResponsibleForImplementation;
        case sacopModules.IMPLEMENTATIONFORACTION:
            return record.sendToResponsibleForClosingOfActions;
        case sacopModules.CLOSINGFORSHARES:
            return Boolean(record.annulled || record.sendToResponsibleForVerificationEffectivenessAction);
        /*
        case sacopModules.VERIFICATIONEFFECTIVENESSACTION:
            return false;
        */
        default:
            return false;
    }
}

const CustomButton = ({ title, icon, onClick, action }) => {
    console.log(`CustomButton - Action: ${action}`);
    const record = useRecordContext(); 

    if (!record) {
        console.log(`CustomButton - record value not valid: ${record}`);
        return null;
    }

    /*
    TODO: SE DEBE REALIZAR LA VALIDACIÓN ACORDE DE LA ACCIÓN, PARA ESO SE DEBE TENER EN MENTE
          LA CANTIDAD DE VECES QUE SE USA EL BOTON DENTRO DE SACOP.
          
        1.- SOLICITUD DE ACCIÓN   ---> ENVIO PARA EL RESPONSABLE DE ANÁLISIS.
        2.- ANÁLISIS DEL PROBLEMA ---> ENVIO PARA EL RESPONSABLE DE IMPLEMENTACIÓN.
        3.- IMPLEMENTACIÓN        ---> ENVIO PARA EL RESPONSABLE DE CIERRE.
        4.- CIERRE                ---> ENVIO PARA EL RESPONSABLE DE VERIFICACIÓN.
        5.- VERIFICACIÓN          ---> YA NO SE REALIZAN ENVIOS.
    */

    const isDisabled = isActionButtonDisabled(record, action);

    return (
        <Button key={record.id} label={title} disabled={isDisabled} onClick={() => onClick(record)}>
            {icon}
        </Button>
    );
};

export default CustomButton;