import { BooleanField, Datagrid, Loading, RaRecord, TextField, useListContext, useTranslate } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";
import sacopModules from "../../../../shared/utilities/sacopModules";
import { Laboratory } from "../../../../shared/types/types";
import NoDataFound from "../../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<Laboratory>();
    if (isLoading || data.length === 0) {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />;
    }

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="resources.laboratory.fields.name"  variant="body1" />
            <TextField source="type" label="resources.laboratory.fields.type.name" variant="body1" />
            <BooleanField source="active" variant="body1" />

            <CoreEditButton permission="BACKOFFICE_LABORATORY_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_LABORATORY_D" />
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
