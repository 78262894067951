import buildRestProvider from "../shared/utilities/simpleRestProvider"

import {
  CreateParams,
  UpdateParams,
  DataProvider,
  fetchUtils,
} from "react-admin";
import { LaboratoryRegistry } from "../shared/types/types";

const fetchJson = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("X-Correlation-Id", "foobar");
  options.headers.set("X-App-Id", "00e9396e-6c63-4834-8492-a1db4edc6421");
  options.headers.set(
    "Authorization",
    `Bearer ${localStorage.getItem("auth")?.toString()}`
  );
  return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = buildRestProvider(
  String(process.env.REACT_APP_API_URL),
  fetchJson
);

const createPostFormData = async (
  params:
    | CreateParams<LaboratoryRegistry>
    | UpdateParams<LaboratoryRegistry>
) => {
  const formData = new FormData();

  const laboratoryRegistry = params.data

  if(laboratoryRegistry.qualityReport?.file) {
    if(laboratoryRegistry.qualityReport.file.rawFile) {
      formData.append("qualityReportFile", laboratoryRegistry.qualityReport.file.rawFile);
    } else {
      const res = await fetch(laboratoryRegistry.qualityReport.file.src!);
      const data = await res.blob();
      formData.append("qualityReportFile", new File([data], laboratoryRegistry.qualityReport.file.title!, {type: res.headers.get("content-type")!}));
    }
  }

  formData.append(
    "laboratoryRegistryInfo",
    new Blob([JSON.stringify(laboratoryRegistry)], {
      type: "application/json",
    })
  );
  return formData;
};

export const dataProvider: DataProvider = {
  ...baseDataProvider,
  create: async (resource, params) => {
    if (resource === "laboratoryRegistry") {
      const formData = await createPostFormData(params);
      return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({ data: json }));
    }

    return baseDataProvider.create(resource, params);
  },
  update: async (resource, params) => {
    if (resource === "laboratoryRegistry") {
      const formData = await createPostFormData(params);
      return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}`, {
          method: "PUT",
          body: formData,
        })
        .then(({ json }) => ({ data: json }));
    }

    return baseDataProvider.update(resource, params);
  },
};
