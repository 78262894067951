import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FileField, FileInput } from "react-admin";

export default function OtherFilesInput() {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid container lg={10} lgOffset={1} spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6">OTROS ARCHIVOS</Typography>
        </Grid>
        <Grid xs={12} md={6}>

        <FileInput
          source="verificationListFile"
          label="resources.weight_report_operation.fields.verification_list"
        >
          <FileField source="src" title="title" />
        </FileInput>
        </Grid>
        <Grid xs={12} md={6}>
        <FileInput
          source="dusCopy"
          label="resources.weight_report_operation.fields.dus_copy"
        >
          <FileField source="src" title="title" />
        </FileInput>
        </Grid>
        <Grid xs={12} md={6}>
        <FileInput
          source="customDeliveryLetter"
          label="resources.weight_report_operation.fields.custom_delivery_letter"
        >
          <FileField source="src" title="title" />
        </FileInput>
        </Grid>
        <Grid xs={12} md={6}>
        <FileInput
          multiple
          source="otherFiles"
          label="resources.weight_report_operation.fields.other_files"
        >
          <FileField source="src" title="title" />
        </FileInput>
        </Grid>
      </Grid>
    </Grid>
  );
}
