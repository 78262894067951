import {
  Loading,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetList,
  useRecordContext,
} from "react-admin";
import useSamplingLocation from "../../../../../shared/hooks/useSamplingLocation";
import { Port } from "../../../../../shared/types/types";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Divider, TextField, Typography } from "@mui/material";
import StoresInput from "./StoresInput";
import { useEffect, useState } from "react";

export default function ProcedureInput() {
  const record = useRecordContext();
  const [foldersChoices, setFoldersChoices] = useState([]);
  const [currentFile, setCurrentFile] = useState('');
  const { samplingLocData, samplingLocIsLoading } = useSamplingLocation();

  //if (samplingLocIsLoading) return <Loading/>

  const instrumentChoices = [
    {
      id: "Pesometro",
      name: "Pesometro",
    },
    {
      id: "Balanza",
      name: "Balanza",
    },
  ];

  const samplingSystemChoices = [
    {
      id: "Automático",
      name: "Automático",
    },
    {
      id: "Manual",
      name: "Manual",
    },
  ];
  
  const weightValidation = (value, allValues) => {
    if (!value) {
      return undefined;
    }
    if (!Number.isInteger(value) || value < 0) {
      return 'Debe ingresar un valor mayor o igual a 0';
    }
    return undefined;
  }

  const validateNumberRegex = (value) => {
    const regex = /\./g;
    if (regex.test(value)) {
      const [, decimal] = value.toString().split('.');
      if (decimal?.length > 7) {
        return 'Debe ser un número positivo de hasta 7 dígitos, incluyendo decimales.';
      }
    }
    return undefined;
  };

  const validateWeight = [number(), weightValidation];
  const validateWet = [number(), validateNumberRegex];

  useEffect(() => {
    console.log(record);

    if (!record || !record.folders) {
      return;
    }

    const folders = record.folders.map(folder => {
      return {
        ...folder,
        name: folder.code
      }
    });

    setFoldersChoices(folders);

    if (record.procedureCode ) {
      const currentFolder = record.folders.filter(folder => folder.id === record.procedureCode)[0];

      if (!currentFolder) {
        setCurrentFile('Por favor seleccione un procedimiento');
        return;
      }

      const cleanedFolderName = currentFolder?.code?.replace(/\s+/g, '').toLowerCase();

      const currentFile = currentFolder?.childrens?.filter(folderFile => {
        const cleanedFileName = folderFile.code?.replace(/\s+/g, '').toLowerCase();
        if (cleanedFolderName === cleanedFileName) {
          return folderFile;
        }
      })[0];

      const data = currentFile?.versions;
                  
      const mostRecentFile = data.reduce((latest, current) => {
        const currentDate = new Date(current.publicationDate);
        return latest ? (currentDate > new Date(latest.publicationDate) ? current : latest) : current;
      }, null);

      setCurrentFile(mostRecentFile ? mostRecentFile.fileInfo.title : "No hay archivos disponibles");
    }

  }, [])

  if (samplingLocIsLoading) return <Loading/>

  return (
    <>
      <Grid container sx={{ width: "100%" }}>
        <Grid container lg={10} lgOffset={1} spacing={1}>
          <Grid xs={12}>
            <Typography variant="h6">PROCEDIMIENTO</Typography>
          </Grid>
          <Grid md={4} xs={12}>
            {/* TODO: this should hit chapterTree endpoint filtering by procedure */}
            {record && 
              <SelectInput
                fullWidth
                choices={foldersChoices}
                variant="outlined"
                helperText={false}
                source="procedureCode"
                label="resources.weight_report_operation.fields.procedure_code"
                onChange={(e) => { 
                  // temp code
                  if (record) {
                    const currentFolder = record?.folders?.filter(folder => folder.id === e.target.value)[0];
                    const cleanedFolderName = currentFolder?.code?.replace(/\s+/g, '').toLowerCase();
                    const currentFile = currentFolder?.childrens?.filter(folderFile => {
                      const cleanedFileName = folderFile.code?.replace(/\s+/g, '').toLowerCase();
                      if (cleanedFolderName === cleanedFileName) {
                        return folderFile;
                      }
                    })[0];

                    const data = currentFile?.versions;

                    const mostRecentFile = data.reduce((latest, current) => {
                      const currentDate = new Date(current.publicationDate);
                      return latest ? (currentDate > new Date(latest.publicationDate) ? current : latest) : current;
                    }, null);

                    setCurrentFile(mostRecentFile ? mostRecentFile.fileInfo.title : "No hay archivos disponibles");
                  }
                }}
              />
            }
          </Grid>
          <Grid md={4} xs={12}>
            {/* TODO: modify labels */}
            {record && 
              <TextField
                label="Archivo"
                disabled
                fullWidth
                value={currentFile}
                defaultValue="Por favor seleccione un procedimiento"
                variant="outlined"
              />
            }
          </Grid>
          <Grid md={4} xs={12}>
            <SelectInput
              source="weightMeasuringInstrument"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.weight_measuring_instrument"
              choices={instrumentChoices}
            />
          </Grid>
          <Grid md={4} xs={12}>
            <SelectInput
              source="weightSamplePlace"
              label="resources.weight_report_operation.fields.weight_sample_place"
              fullWidth
              variant="outlined"
              helperText={false}
              choices={samplingLocData}
            />
          </Grid>
          <Grid md={2.5} xs={12}>
            <SelectInput
              source="samplingSystem"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.sampling_system"
              choices={samplingSystemChoices}
            />
          </Grid>
          <Grid md={4} xs={12}>
            <SelectInput
              source="humiditySamplePlace"
              label="resources.weight_report_operation.fields.humidity_sample_place"
              fullWidth
              variant="outlined"
              helperText={false}
              choices={samplingLocData}
            />
          </Grid>
          <Grid md={3} xs={12}>
            <NumberInput
              source="grossWeight"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.gross_weight"
              step={1}
              min={0}
              validate={validateWeight}
            />
          </Grid>
          <Grid md={2.5} xs={12}>
            <NumberInput
              source="tareWeight"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.tare_weight"
              step={1}
              min={0}
              validate={validateWeight}
            />
          </Grid>
          <Grid md={2.8} xs={12}>
            <NumberInput
              source="dryNetWeight"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.dry_net_weight"
              step={1}
              min={0}
              validate={validateWeight}
            />
          </Grid>
          <Grid md={3.2} xs={12}>
            <NumberInput
              source="wetNetWeight"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.wet_net_weight"
              step={1}
              min={0}
              validate={validateWeight}
            />
          </Grid>
          <Grid md={3} xs={12}>
            <NumberInput
              source="humidityPercent"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.humidity_percent"
              validate={validateWet} 
            />
          </Grid>
          <Grid md={3} xs={12}>
            <NumberInput
              source="totalGrossWeight"
              fullWidth
              variant="outlined"
              helperText={false}
              label="resources.weight_report_operation.fields.total_gross_weight"
              step={1}
              min={0}
              validate={validateWeight}
            />
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">Bodega</Typography>
          </Grid>
          <Grid xs={12}>
            <StoresInput />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
