import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_WEIGHT_REPORT_OPERATION_C" />
        {/* CHANGEME */}
        <SortButton fields={['id']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const weightReportOperationFilters = [
    <SearchInput source="q" alwaysOn />,

    /* CHANGEME */
    <TextInput source="id" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    return (
        <MobileGrid  {...listContext} />
    );
}

const WeightReportOperationList = () => {
    return (<List
        /* CHANGEME */
        sort={{ field: 'id', order: 'DESC' }}
        perPage={25}
        filters={weightReportOperationFilters}
        actions={<ListActions />}
        title="Registro de Exportaciones"
        
    >
        <TabbedDatagrid />
    </List>);
};

export default WeightReportOperationList;
