import { useNotify, useTranslate } from "react-admin";
import fetchDataService from "../../../utilities/fetchs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useState } from "react";

export default function DeleteChapterButton({
  chapterTreeId,
  currentNode,
  handleTreeChange,
  currentNodeEmpty,
}) {
  const translate = useTranslate();
  const notify = useNotify();
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);

  const handleDelete = async (e) => {
    const { deleteChapterTreeData } = fetchDataService();
    const data = {
      id: chapterTreeId,
      root: currentNode,
      chapterNodeId: currentNode.id
    };

    deleteChapterTreeData(chapterTreeId, data).then((res) => {
      handleTreeChange();
      notify(translate("ra.notification.deleted", { smart_count: 1 }), {
        type: "info",
      });
    });
  };

  const handleClickOpen = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const handleClose = () => {
    setOpenDeleteConfirmationDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDeleteConfirmationDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar Capítulo
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Está seguro que desea eliminar el elmento{" "}
            <em>
              <b>{currentNode.name}</b>
            </em>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>No</Button>
          <Button variant="outlined" onClick={handleDelete} autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        startIcon={<Delete />}
        variant="text"
        disabled={currentNodeEmpty}
        onClick={handleClickOpen}
      >
        Eliminar
      </Button>
    </>
  );
}
