import { Box, Button, Stack } from "@mui/material";
import SaveChapterButton from "../SaveChapterButton";
import { Cancel, Edit } from "@mui/icons-material";
import AddChapterButton from "./AddChapterButton";
import DeleteChapterButton from "./DeleteChapterButton";
import CancelChapterButton from "./CancelChapterButton";
import ModifyChapterButton from "./ModifyChapterButton";

export default function ChapterButtons({
  chapterTreeId,
  updateButtonClicked,
  setUpdateButtonClicked,
  currentNode,
  handleTreeChange,
  inputData,
  setInputData
}) {
  const currentNodeEmpty = currentNode.name === "";

  return (
    <Box>
      {updateButtonClicked ? (
        <>
          <SaveChapterButton
            chapterTreeId={chapterTreeId}
            currentNode={currentNode}
            handleTreeChange={handleTreeChange}
            setUpdateButtonClicked={setUpdateButtonClicked}
            inputData={inputData}
          />
          <CancelChapterButton
            setUpdateButtonClicked={setUpdateButtonClicked}
            resetInput={() => {
              setInputData({
                type: currentNode.type,
                name: currentNode.name,
                code: currentNode.code,
                active: currentNode.active
              })
            }}
            updateButtonClicked={updateButtonClicked}
          />
        </>
      ) : (
        <>
          <Box sx={{display: "flex"}}>
            {currentNode.type === "d" && (
              <AddChapterButton
                currentNodeEmpty={currentNodeEmpty}
                chapterTreeId={chapterTreeId}
                currentNode={currentNode}
                handleTreeChange={handleTreeChange}
              />
            )}
            <ModifyChapterButton
              setUpdateButtonClicked={setUpdateButtonClicked}
              updateButtonClicked={updateButtonClicked}
              currentNodeEmpty={currentNodeEmpty}
            />
            <DeleteChapterButton
              chapterTreeId={chapterTreeId}
              currentNode={currentNode}
              handleTreeChange={handleTreeChange}
              currentNodeEmpty={currentNodeEmpty}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
