import * as React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const CalibrationEquipmentDueExpire = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/instrument"
            icon={ConstructionIcon}
            title={translate('resources.dashboard.calibrationEquipmentDueExpire')}
            subtitle={value}
        />
    );
};

export default CalibrationEquipmentDueExpire;