import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Company } from "../../../shared/types/types";
import NormativeForm from "./NormativeForm";

const NormativeEdit = () => (
    <Edit title={<CompanyTitle />} component="div">
        <NormativeForm />
    </Edit>
);

const CompanyTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext<Company>();
    return record ? (
        <span>
            {translate('resources.commands.title', {
                module: 'Normativa',
                reference: record.name,
            })}
        </span>
    ) : null;
};

export default NormativeEdit;
