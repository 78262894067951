import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MasterOfSharesList from './MasterOfSharesList';
import MasterOfSharesEdit from './MasterOfSharesEdit';
import MasterOfSharesShow from './MasterOfSharesShow';

export default {
    list: MasterOfSharesList,
    edit: MasterOfSharesEdit,
    icon: AssignmentIndIcon,
    show: MasterOfSharesShow,
}