import UsersWithoutDocumentation from "../../../shared/components/cards/UsersWithoutDocumentation";
import CalibrationEquipmentDueExpire from "../../../shared/components/cards/CalibrationEquipmentDueExpire";
import ActionRequestPending from "../../../shared/components/cards/ActionRequestPending";
import PendingExportRecord from "../../../shared/components/cards/PendingExportRecord";
import Welcome from "./Welcome";
import React, { CSSProperties, useMemo } from "react";
import { useGetList, usePermissions } from "react-admin";
import { Instrument, Sacop, User } from "../../../shared/types/types";

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

interface State {
    nbUsersWithoutDocumentation?: string;
    nbCalibrationEquipmentDueExpire?: string;
    nbActionRequestPending?: string;
    nbPendingExportRecord?: string;
}

export const Dashboard = () => {

    const { isLoading, permissions } = usePermissions();

    const { data: users, total: totalUsersWithoutDocumentation, error: errorUsersWithoutDocumentation } = useGetList<User>('user', {
        filter: { cvFile: null },
        sort: { field: 'username', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    });

    const { data: instruments, total: totalCalibrationEquipmentDueExpire, error: errorCalibrationEquipmentDueExpire } = useGetList<Instrument>('instrument', {
        filter: { requireCalibration: true },
        sort: { field: 'name', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    });

    const { data: requestForAction, total: totalActionRequestPending, error: errorActionRequestPending } = useGetList<Sacop>('requestForAction', {
        filter: { verificationStatusId: 3 },
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    });

    const { data: pendingExportRecord, total: totalPendingExportRecord, error: errorPendingExportRecord } = useGetList<Sacop>('weightReportOperation', {
        filter: {},
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    });


    const aggregation = useMemo<State>(() => {
        return {
            nbUsersWithoutDocumentation: String(totalUsersWithoutDocumentation),
            nbCalibrationEquipmentDueExpire: String(totalCalibrationEquipmentDueExpire),
            nbActionRequestPending: String(totalActionRequestPending),
            nbPendingExportRecord: String(totalPendingExportRecord),
        };
    }, [users, instruments, requestForAction, pendingExportRecord]);

    const { nbUsersWithoutDocumentation, nbCalibrationEquipmentDueExpire, nbActionRequestPending, nbPendingExportRecord } = aggregation;

    return (isLoading
        ? <></>
        :
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />

                {
                    (permissions.some(x => x === 'BACKOFFICE_USER_R') || permissions.some(x => x === 'BACKOFFICE_INSTRUMENT_R') ? (
                        <div style={styles.flex}>
                            <div style={styles.leftCol}>
                                <div style={styles.flex}>
                                    {
                                        (permissions.some(x => x === 'BACKOFFICE_USER_R') ? (
                                            <><UsersWithoutDocumentation value={nbUsersWithoutDocumentation} /><Spacer /></>
                                        ) : (
                                            <></>
                                        )
                                        )
                                    }
                                    {
                                        (permissions.some(x => x === 'BACKOFFICE_INSTRUMENT_R') ? (
                                            <> <CalibrationEquipmentDueExpire value={nbCalibrationEquipmentDueExpire} /></>
                                        ) : (
                                            <></>
                                        )
                                        )
                                    }
                                </div>
                            </div>
                            <div style={styles.rightCol}>
                                <div style={styles.flex}>
                                    <ActionRequestPending value={nbActionRequestPending} />
                                    <Spacer />
                                    <PendingExportRecord value={nbPendingExportRecord} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={styles.flex}>
                            <div style={styles.leftCol}>
                                <div style={styles.flex}>
                                    <ActionRequestPending value={nbActionRequestPending} />
                                    <Spacer />
                                    <PendingExportRecord value={nbPendingExportRecord} />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

