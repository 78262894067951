import { MenuItemLink } from 'react-admin';
import validatePermissions from '../../../shared/components/validatePermissions';
import { ExtendedMenuItemLinkProps } from '../../../shared/components/core/MenuItemLinkProps';

const MenuItemLinkDCore = (props: ExtendedMenuItemLinkProps) => {

    const isValid = validatePermissions(props.permission)

    return (isValid ? <MenuItemLink {...props} /> : null);
};

export default MenuItemLinkDCore;
