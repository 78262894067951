import { Box, Typography } from "@mui/material";

export default function NoDataFound() {

  return (
      <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
                No se han encontrado resultados
          </Typography>
      </Box>
  );
}
