import {
  TabbedForm,
  TextInput,
  required,
  SelectInput,
  useGetList,
  Loading,
  BooleanInput,
} from "react-admin";

import OperationForm from "../../../shared/components/operationRegistry/OperationForm";

import ElementSampleInput from "./ElementSampleInput";
import QualityReportForm from "./QualityReportForm";
import FormToolbar from "../../../shared/components/toolbar/FormToolbar";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";

interface LaboratoryFormProps {
  edit?: boolean;
}

export default function LaboratoryForm({ edit }: LaboratoryFormProps) {
  const { data: internalLaboratories, isLoading: internalLabIsLoading } =
    useGetList("laboratory", {
      filter: { type: "Interno" },
      sort: { field: "name", order: "DESC" },
    });

  const { data: externalLaboratories, isLoading: externalLabIsLoading } =
    useGetList("laboratory", {
      filter: { type: "Subcontratado" },
      sort: { field: "name", order: "DESC" },
    });

  if (internalLabIsLoading || externalLabIsLoading) {
    return <Loading />;
  }

  return (
    <TabbedForm toolbar={<FormToolbar edit={edit ? true : undefined} />}>
      <TabbedForm.Tab label="resources.laboratory_registry.title">
        <Grid container sx={{ width: "100%", mb: 3, mt: 1 }}>
          <Grid container lg={10} lgOffset={1} spacing={2}>
            <Typography sx={{ ml: 1 }} variant="h6">
              REGISTRO DE LABORATORIO
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ width: "100%" }}>
          <Grid container lg={10} lgOffset={1} spacing={2}>
            <Grid xs={12} md={6}>
              <TextInput
                source="operationRegistry.dus"
                label="DUS"
                validate={required()}
                helperText={false}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <SelectInput
                source="oi.id"
                label="resources.laboratory_registry.fields.oi_name"
                fullWidth
                validate={required()}
                helperText={false}
                choices={internalLaboratories}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <SelectInput
                source="outsourcedLaboratory"
                label="resources.laboratory_registry.fields.outsourced_laboratory"
                helperText={false}
                fullWidth
                choices={externalLaboratories}
              />
            </Grid>
            <Grid xs={12} md={6}>
              {/* TODO: fix type to be coherent */}
              <TextInput
                source="outsourcedAnalytes"
                helperText={false}
                label="resources.laboratory_registry.fields.outsourced_analytes"
                fullWidth
                // validate={required()}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <BooleanInput
                source="active"
                helperText={false}
                label="resources.laboratory_registry.fields.active"
              />
            </Grid>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="Elementos"
      >
        <ElementSampleInput
          source="payableElements"
          label="resources.laboratory_registry.fields.payable_element"
        />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="resources.laboratory_registry.fields.quality_report.name"
      >
        <QualityReportForm />
      </TabbedForm.Tab>
    </TabbedForm>
  );
}
