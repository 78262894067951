import { Edit, useTranslate, useRecordContext } from "react-admin";
import ExportForm from "./ExportForm";

const CompanyTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return record ? (
        <span>
            {translate('resources.commands.title', {
                module: 'Exportación',
                reference: record.name,
            })}
        </span>
    ) : null;
};

export default function RegisterFormEdit() {
  return (
    <Edit title={<CompanyTitle />} component="div">
      <ExportForm />
    </Edit>
  )
}
