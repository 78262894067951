import {
  ArrayInput,
  FormDataConsumer,
  SelectInput,
  SelectInputProps,
  SimpleFormIterator,
  TextInput,
  required,
  NumberInput,
  Form,
} from "react-admin";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { Fragment } from "react";
import { Divider, TextField, Typography } from "@mui/material";

const elements = [
  { id: "Cu", name: "Cu" },
  { id: "Au", name: "Au" },
  { id: "Ag", name: "Ag" },
];

const metrics = [
  { id: "1", name: "%" },
  { id: "2", name: "g/tm" },
];

const ElementSampleInput = ({ source, label }) => {
  return (
    <Grid sx={{ width: "100%" }} container lg={10} lgOffset={1} spacing={2}>
      <Grid xs={12}>
          <Typography variant="h6">ELEMENTOS</Typography>
      </Grid>
      <Grid xs={12} md={4}>
        <TextField
          defaultValue="Mo"
          disabled
          fullWidth
          label="Símbolo Químico"
        />
      </Grid>
      <Grid xs={12} md={4}>
        <TextField defaultValue="%" label="Métrica" fullWidth disabled />
      </Grid>
      <Grid xs={12} md={4}>
        <NumberInput
          source="mo.value"
          fullWidth
          helperText={false}
          label="resources.laboratory_registry.fields.element_sample.value"
          // validate={required()}
        />
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid xs={12} md={6} >
        <Grid xs={12}>
          <Typography variant="h6">Penalizables</Typography>
          <ArrayInput
            label="resources.laboratory_registry.fields.penalizable_element"
            source="penalizableElements"
            // validate={required()}
          >
            <SimpleFormIterator inline>
              <SelectInput
                source="symbol"
                choices={elements}
                // validate={required()}
                label="resources.laboratory_registry.fields.element_sample.symbol"
              />
              <SelectInput
                source="metric"
                choices={metrics}
                label="resources.laboratory_registry.fields.element_sample.metric"
                // validate={required()}
              />
              <NumberInput
                source="value"
                helperText={false}
                label="resources.laboratory_registry.fields.element_sample.value"
                // validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>

      <Grid xs={12} md={6}>
        <Grid xs={12}>
          <Typography variant="h6">Pagables</Typography>
          <ArrayInput
            source="payableElements"
            label="resources.laboratory_registry.fields.payable_element"
            // validate={required()}
          >
            <SimpleFormIterator inline>
              <SelectInput
                source="symbol"
                choices={elements}
                // validate={required()}
                label="resources.laboratory_registry.fields.element_sample.symbol"
              />
              <SelectInput
                source="metric"
                choices={metrics}
                label="resources.laboratory_registry.fields.element_sample.metric"
                // validate={required()}
              />
              <NumberInput
                source="value"
                helperText={false}
                label="resources.laboratory_registry.fields.element_sample.value"
                // validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default ElementSampleInput;
