import {
    Admin,
    Resource,
    combineDataProviders,
    fetchUtils,
    CustomRoutes,
    localStorageStore,
    StoreContextProvider,
} from "react-admin";

import { Route } from "react-router-dom";

import { Login, Layout } from "./dcore/layout";

import polyglotI18nProvider from "ra-i18n-polyglot";
import spanishMessages from "./dcore/i18n/es";
import authProvider from "./authProvider";
import { lightTheme } from "./dcore/layout/themes";
import perfil from "./dcore/lateral/perfil";
import rol from "./dcore/admin/rol";
import user from "./dcore/admin/user";
import localization from "./ahk/localization";
import normative from "./ahk/normative";
import exportResource from "./ahk/export";
import laboratoryRegistry from "./ahk/laboratoryRegistry";
import laboratory from "./dcore/backoffice/laboratory";
import customsHouse from "./dcore/backoffice/customsHouse";
import exporter from "./dcore/backoffice/exporter"
import instrument from "./dcore/backoffice/instrument";
import port from "./dcore/backoffice/port";
import weightReportOperation from "./dcore/backoffice/weightReportOperation";
import { dataProvider as weightReportDataProvider } from "./weightReportDataProvider"
import { dataProvider as laboratoryDataProvider } from "./laboratoryDataProvider"
import { dataProvider as laboratoryRegistryDataProvider } from "./laboratoryRegistryDataProvider"
import { dataProvider as userDataProvider } from "./userDataProvider";
import { dataProvider as requestForActionDataProvider } from "./requestForActionDataProvider";
import { dataProvider as implementationOfActionDataProvider } from "./implementationOfActionDataProvider";
import { dataProvider as instrumentDataProvider } from "./instrumentDataProvider";
import samplingLocation from "./dcore/backoffice/samplingLocation";
import requestForAction from "./dcore/backoffice/requestForAction";
import analysisOfShares from "./dcore/backoffice/analysisOfShares";
import implementationOfAction from "./dcore/backoffice/implementationOfAction";
import closingOfShares from "./dcore/backoffice/closingOfShares";
import verificationEffectivenessAction from "./dcore/backoffice/verificationEffectivenessAction";
import masterOfShares from "./dcore/backoffice/masterOfShares";
import sharesCommitee from "./dcore/backoffice/sharesCommitee";
import sacop from "./dcore/backoffice/sacop";
import SecurityView from "./dcore/user/security/SecurityView";
import SetupMfa from "./dcore/user/security/MFA/SetupMfa";
import buildRestProvider from "../shared/utilities/simpleRestProvider"
import { Dashboard } from './ahk/dashboard';

const fetchJson = (url, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    // add your own headers here
    options.headers.set("X-Correlation-Id", "foobar");
    options.headers.set("X-App-Id", "00e9396e-6c63-4834-8492-a1db4edc6421");
    options.headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("auth")?.toString()}`
    );
    return fetchUtils.fetchJson(url, options);
};

const backOfficeService = buildRestProvider(
    `${process.env.REACT_APP_API_URL}`,
    fetchJson
);

const dataProvider = combineDataProviders((resource): any => {
    switch (resource) {
        case "rol":
        case "localization":
        case "normative":
        case "export":
        case "customsHouse":
        case "exporter":
        case "port":
        case "course":
        case "samplingLocation":
        case "analysisOfShares":
        case "closingOfShares":
        case "verificationEffectivenessAction":
        case "masterOfShares":
        case "sharesCommitee":
        case "sacop":
            return backOfficeService;
        case "instrument":
            return instrumentDataProvider;
        case "implementationOfAction":
            return implementationOfActionDataProvider;
        case "requestForAction": 
            return requestForActionDataProvider;
        case "weightReportOperation":
            return weightReportDataProvider;
        case "laboratory":
            return laboratoryDataProvider;
        case "laboratoryRegistry":
            return laboratoryRegistryDataProvider;
        case "user":
            return userDataProvider;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

const i18nProvider = polyglotI18nProvider(
    (locale) => (spanishMessages),
    "es", // Default locale
    [
        { locale: "es_CL", name: "Español", flag: "en" },
    ]
);

const store = localStorageStore(undefined, 'AHK');

const App = () => {
    return (
        <Admin
            title="Administrador"
            dashboard={Dashboard}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={Login}
            disableTelemetry
            store={store}
            layout={Layout}
            i18nProvider={i18nProvider}
            requireAuth
            theme={lightTheme}
        >
            <Resource name="rol" {...rol} />
            <Resource name="user" {...user} />
            <Resource name="localization" {...localization} />
            <Resource name="normative" {...normative} />
            <Resource name="perfil" {...perfil} />
            <Resource name="export" {...exportResource} />
            <Resource name="laboratoryRegistry" {...laboratoryRegistry} />
            <Resource name="laboratory" {...laboratory} />
            <Resource name="customsHouse" {...customsHouse} />
            <Resource name="exporter" {...exporter} />
            <Resource name="instrument" {...instrument} />
            <Resource name="port" {...port} />
            <Resource name="weightReportOperation" {...weightReportOperation} />
            <Resource name="samplingLocation" {...samplingLocation} />
            <Resource name="requestForAction" {...requestForAction} />
            <Resource name="analysisOfShares" {...analysisOfShares} />
            <Resource name="implementationOfAction" {...implementationOfAction} />
            <Resource name="closingOfShares" {...closingOfShares} />
            <Resource name="verificationEffectivenessAction" {...verificationEffectivenessAction} />
            <Resource name="masterOfShares" {...masterOfShares} />
            <Resource name="sharesCommitee" {...sharesCommitee} />
            <Resource name="sacop" {...sacop} />
            <CustomRoutes>
                <Route path="/security" element={<SecurityView/>} />
                <Route path="/security/setup-mfa" element={<SetupMfa/>} />
            </CustomRoutes>
        </Admin>
    );
};


const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;

