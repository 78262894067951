import ProductIcon from '@mui/icons-material/Collections';
import NormativeList from './NormativeList';
import NormativeCreate from './NormativeCreate';
import NormativeEdit from './NormativeEdit';

export default {
    list: NormativeList,
    create: NormativeCreate,
    edit: NormativeEdit,
    icon: ProductIcon,
};
