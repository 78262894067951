import {
    BooleanInput,
    DeleteButton,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
    required,
    useRecordContext,
} from "react-admin";
import { Company } from "../../../shared/types/types";
import { Grid, Box } from "@mui/material";

import image1 from "./image1.jpg";

import BarTreeView from "../../../shared/components/treeview/BarTreeView";
import CharacterNormativeList from "../../../shared/components/character-normative/CharacterNormative";

const NormativeForm = () => {
    const record = useRecordContext<Company>();

    return (
        <TabbedForm
            toolbar={false}
            defaultValues={{ id: "0000-0000-0000-0000" }}
        >
            <TabbedForm.Tab label="resources.normative.title">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            source="code"
                            label="resources.normative.fields.code"
                            fullWidth
                            validate={required()}
                        />
                        <TextInput
                            source="name"
                            label="resources.normative.fields.name"
                            fullWidth
                            validate={required()}
                        />
                        <TextInput
                            source="description"
                            label="resources.normative.fields.description"
                            fullWidth
                            validate={required()}
                        />
                        <BooleanInput
                            source="active"
                            label="resources.normative.fields.active"
                        />

                        <BooleanInput
                            source="operative"
                            label="resources.normative.fields.isrecord"
                        />
                        <Toolbar>
                            <SaveButton />
                            <DeleteButton />
                        </Toolbar>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                borderRadius: 2,
                                height: "500px",
                                backgroundColor: "primary.main",
                            }}
                        >
                            <Box
                                component="img"
                                src={image1}
                                alt=""
                                sx={{
                                    borderRadius: "inherit",
                                    width: "100%",
                                    height: "inherit",
                                    objectPosition: "center",
                                    objectFit: "cover",
                                    opacity: "0.5",
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>

            {record && (
                <TabbedForm.Tab label="resources.normative.structure">
                    <BarTreeView />
                </TabbedForm.Tab>
            )}
        </TabbedForm>
    );
};

export default NormativeForm;
