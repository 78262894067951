import ProductIcon from '@mui/icons-material/Collections';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const PendingExportRecord = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/weightReportOperation"
            icon={ProductIcon}
            title={translate('resources.dashboard.pendingExportRecord')}
            subtitle={value}
        />
    );
};

export default PendingExportRecord;