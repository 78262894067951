import {
  AutocompleteInput,
  BooleanInput,
  Loading,
  SimpleForm,
  TabbedForm,
  TextInput,
  required,
  useGetList,
} from "react-admin";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";

interface PortFormProps {
  edit?: boolean;
  formTitle: string;
}

const PortForm = ({ edit, formTitle }: PortFormProps) => {
  const { data: localizations, isLoading: localizationsIsLoading } = useGetList(
    "localization",
    {
      filter: { active: true },
      sort: { field: "name", order: "DESC" },
      pagination: { page: 1, perPage: 999 },
    }
  );

  if (localizationsIsLoading) {
    return <Loading />;
  }

  const optionRenderer = localization => `${localization.code} - ${localization.name}`;

  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 3 }}
      defaultValues={{ id: "0000-0000-0000-0000" }}
    >
      <Grid container lg={10} lgOffset={1} columnSpacing={2}>
        <Grid xs={12}>
          <Typography sx={{ mb: 2 }} variant="h6">
            {formTitle.toUpperCase()}
          </Typography>
        </Grid>
        <Grid container xs={12} md={6}>
          <Grid xs={12} md={12}>
            <TextInput label="resources.port.fields.name" source="name" validate={required()}/>
          </Grid>
          <Grid xs={12} md={12}>
            <TextInput label="resources.port.fields.code" source="code" validate={required()}/>
          </Grid>
          <Grid xs={12} md={12}>
            <AutocompleteInput
              label="resources.port.fields.cost_center"
              source="costCenterId"
              choices={localizations ? localizations : []}
              optionText={optionRenderer}
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12} md={6}>
            <BooleanInput
              label="resources.port.fields.active"
              source="active"
            />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default PortForm;
