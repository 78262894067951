import {
  BooleanInput,
  SimpleForm,
  TabbedForm,
  TextInput,
  required,
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";

interface SamplingLocationFormProps {
  edit?: boolean;
  formTitle: string;
}

const SamplingLocationForm = ({
  edit,
  formTitle,
}: SamplingLocationFormProps) => (
  <SimpleForm
    toolbar={<FormToolbar edit={edit ? true : undefined} />}
    sx={{ mt: 3 }}
  >
    <Grid container lg={10} lgOffset={1} columnSpacing={2}>
      <Grid xs={12}>
        <Typography sx={{ mb: 2 }} variant="h6">
          {formTitle.toUpperCase()}
        </Typography>
      </Grid>
      <Grid container xs={12} md={6}>
        <Grid xs={12} md={12}>
          <TextInput
            label="resources.sampling_location.fields.name"
            source="name"
            validate={required()}
          />
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12} md={6}>
          <BooleanInput
            label="resources.sampling_location.fields.active"
            source="active"
          />
        </Grid>
      </Grid>
    </Grid>
  </SimpleForm>
);

export default SamplingLocationForm;
