import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Sacop } from "../../../../shared/types/types";
import VerificationEffectivenessActionForm from "./VerificationEffectivenessActionForm";

export default function VerificationEffectivenessActionEdit() {
  return(
      <Edit title={<VerificationEffectivenessActionTitle />}>
          <VerificationEffectivenessActionForm formTitle={<ActionEditTitle />} edit={true}/>
      </Edit>
  );
}

const VerificationEffectivenessActionTitle = () => {
    const sacopRecord = useRecordContext<Sacop>();
    const translate = useTranslate();
    return sacopRecord ? (
        <span>
            {`${translate("resources.sacop.titles.verificationEffectivenessAction.title")}`}
        </span>
    ) : null;
};

const ActionEditTitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.actions.verificationEffectivenessAction.edit.title")}`}
        </span>
    );
};