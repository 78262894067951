import { Theme, useMediaQuery } from "@mui/material";
import { Fragment, useCallback } from "react";
import { TextField, DatagridConfigurable, List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput, BooleanInput, BooleanField } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_LABORATORY_C" />
        <SortButton fields={['name']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const laboratoryFilters = [
    <SearchInput source="q" alwaysOn />,
    <BooleanInput source="active" />,
    <TextInput source="name" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="name" />
                    <BooleanField source="active" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}

const LaboratoryList = () => {
    const getResourceLabel = useGetResourceLabel();
    return (<List
        filterDefaultValues={ { active: true }}
        sort={{ field: 'name', order: 'DESC' }}
        perPage={25}
        filters={laboratoryFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default LaboratoryList;
