import {  Theme, useMediaQuery } from "@mui/material";
import { List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_USER_C" />
        <SortButton fields={['name']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const agentFilters = [
    <SearchInput source="q" alwaysOn />,

    <TextInput source="name" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    return (
        <MobileGrid  {...listContext} />
    );
}

const AgentList = () => {
    const getResourceLabel = useGetResourceLabel();
    return (<List
        filterDefaultValues={ { active: true }}
        sort={{ field: 'name', order: 'DESC' }}
        perPage={25}
        filters={agentFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default AgentList;
