import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Sacop } from "../../../../shared/types/types";
import {truncate} from "../../../../shared/utilities/truncate";
import ImplementationOfActionForm from "./ImplementationOfActionForm";

export default function ImplementationOfActionEdit() {
  return(
      <Edit title={<ImplementationOfActionTitle />}>
          <ImplementationOfActionForm formTitle={<ActionEditTitle />} edit={true}/>
      </Edit>
  );
}

const ImplementationOfActionTitle = () => {
    const sacopRecord = useRecordContext<Sacop>();
    const translate = useTranslate();
    return sacopRecord ? (
        <span>
            {`${translate("resources.sacop.titles.implementationOfAction.title")}`}
        </span>
    ) : null;
};

const ActionEditTitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.actions.implementationOfAction.edit.title")}`}
        </span>
    );
};