import 'proxy-polyfill';
// import '@fontsource/mukta'
import '@fontsource/mukta/300.css'
import '@fontsource/fira-sans/500.css';

import ReactDOM from 'react-dom';

import App from './modules/App';

ReactDOM.render(<App />, document.getElementById('root'));
