import { Create } from "react-admin";
import PortForm from "./PortForm";

const PortCreate = () => (
    <Create>
        <PortForm formTitle="crear puerto" />
    </Create>
);

export default PortCreate;
