import ProductIcon from '@mui/icons-material/Collections';
import ClosingOfSharesEdit from './ClosingOfSharesEdit';
import ClosingOfSharesList from './ClosingOfSharesList';
import ClosingOfSharesShow from './ClosingOfSharesShow';

export default {
    list: ClosingOfSharesList,
    edit: ClosingOfSharesEdit,
    icon: ProductIcon,
    show: ClosingOfSharesShow,
};