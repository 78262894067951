import { Delete, MailOutlineSharp, PhoneOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  BooleanInput,
  Datagrid,
  FileField,
  FileInput,
  List,
  Loading,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  email,
  number,
  required,
  useEditContext,
  useGetList,
  ReferenceField,
  useListContext,
  DateField,
  RaRecord,
  useGetManyReference,
  useRecordContext,
  useTranslate,
  WithListContext,
  SelectInputProps,
  Link,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import LocalizationArrayInput from "../../../../shared/components/localization/LocalizationArrayInput";
import PhoneInput from "../../../../shared/components/phoneInput/PhoneInput";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import { useEffect, useState } from "react";
import PasswordGenerator from "./PasswordGenerator";

interface UserFormProps {
  formTitle: string;
  edit?: boolean;
}



const getVerifyDigit = (rutWithoutDigit) => {
  var rutSinDig = rutWithoutDigit;
  var suma = 0;
  var multiplo = 2;

  for (var i = rutSinDig.length - 1; i >= 0; i--) {
    suma += parseInt(rutSinDig.charAt(i)) * multiplo;
    multiplo = multiplo < 7 ? multiplo + 1 : 2;
  }
  const resultado = (11 - (suma % 11));
  return resultado.toString();
};

const UserForm = ({ formTitle, edit }: UserFormProps) => {
  const editContext = useEditContext();
  const [verifyDigit, setVerifyDigit] = useState(
    editContext.record
      ? getVerifyDigit(editContext.record.nrofiscal.toString())
      : ""
  );
  const {
    data: rolData,
    total: rolTotal,
    isLoading: rolIsLoading,
    error: rolError,
  } = useGetList("rol", {
    filter: { active: true },
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "DESC" },
  });

  const validateVerifyDigit = (value) => {
    if (!verifyDigit) return "RUT inválido";

    let parsedVerifyDigit: number;

    if (verifyDigit === "k" || verifyDigit === "K") parsedVerifyDigit = 10;
    else parsedVerifyDigit = parseInt(verifyDigit);

    const resultado = getVerifyDigit(value.toString());

    if (parseInt(resultado) !== parsedVerifyDigit) return "RUT inválido";
    return undefined;
  };

  if (rolIsLoading || rolData?.length === 0) {
    return <Loading />;
  }

  // RaSimpleFormIterator-form
  //
  const optionRenderer = localization => `${localization.code} - ${localization.name}`;
  const moodle = editContext?.record?.userCourseMoodle

  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 3, mb: 3 }}
    >
      <Grid container spacing={2} lg={10} lgOffset={1} xl={8} xlOffset={2}>
        <Grid xs={12}>
          <Typography variant="h6">{formTitle}</Typography>
        </Grid>
        <Grid sm={12} md={6}>
          <TextInput
            fullWidth
            variant="outlined"
            helperText={false}
            source="name"
            label="resources.user.fields.name"
            validate={required()}
          />
        </Grid>
        <Grid sm={12} md={6}>
          <TextInput
            fullWidth
            variant="outlined"
            source="lastname"
            helperText={false}
            label="resources.user.fields.lastname"
            validate={required()}
          />
        </Grid>
        <Grid sm={12} md={6}>
          <TextInput
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineSharp />
                </InputAdornment>
              ),
              disabled: editContext.record !== null,
            }}
            type="email"
            source="mail"
            label="resources.user.fields.mail"
            fullWidth
            helperText={false}
            validate={[required(), email()]}
          />
        </Grid>
        <Grid sm={12} md={6}>
          <PhoneInput
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneOutlined />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            helperText={false}
            source="phone"
            fullWidth
            label="resources.customer.fields.phone"
          />
        </Grid>
        <Grid sm={12} md={5.2}>
          <TextInput
            helperText={false}
            variant="outlined"
            source="nrofiscal"
            InputProps={{ disabled: editContext.record !== null }}
            label="resources.user.fields.nro_fiscal"
            fullWidth
            validate={[
              required(),
              number("Ingrese solo números"),
              validateVerifyDigit,
            ]}
          />
        </Grid>
        <Grid sm={12} md={0.8}>
          <TextField
            value={verifyDigit}
            // value={
            //   editContext.record === null
            //     ? verifyDigit
            //     : getVerifyDigit(editContext.record.nrofiscal.toString())
            // }
            InputProps={{ disabled: editContext.record !== null }}
            onChange={(e: any) => setVerifyDigit(e.target.value)}
            type="text"
            variant="outlined"
            inputProps={{
              maxLength: 1,
              pattern: "[0-9kK]",
            }}
          />
        </Grid>
        <Grid sm={12} md={6}>
          <SelectInput
            source="rol.id"
            variant="outlined"
            label="resources.user.fields.rol"
            fullWidth
            helperText={false}
            validate={required()}
            choices={rolData}
          />
        </Grid>
        <Grid sm={12} md={6}>
          {/*
          <LocalizationArrayInput
            disableClearable={true}
            source="localizationIds"
            helperText={false}
            label="Centro de Costo"
            optionText={optionRenderer}
            variant="outlined"
          />
          */}
          <ReferenceArrayInput
            source="localizationIds"
            reference="localization"
            label="Centro de costo"
            perPage={999}
            filter={{ active: true }}
          >
            <AutocompleteArrayInput
              label="Centro de costo"
              optionText={(choice) => `${choice.code} - ${choice.name}`}
              debounce={500}
            />
          </ReferenceArrayInput>
        </Grid>
        <Grid sm={12} md={6}>
          <Stack spacing={1} direction="row">
            <Box sx={{ display: "flex", aligns: "center", height: "100%" }}>
              {editContext.record != null && (
                  <PasswordGenerator email={editContext.record.mail} />
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid sm={12} md={6}>
          <Stack spacing={1} direction="row">
            <BooleanInput
              sx={{ ml: 1 }}
              helperText={false}
              label="resources.user.fields.active"
              source="active"
            />
          </Stack>
        </Grid>

        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">Documentos Adjuntos</Typography>
        </Grid>
        <Grid xs={12} lg={6}>
          <FileInput source="cv" label="Curriculum">
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
        <Grid xs={12} lg={6}>
          <FileInput source="studiesCert" label="Certificado de Estudios">
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
        <Grid xs={12} lg={6}>
          <FileInput source="permitCert" label="Certificado de Permiso">
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>

        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">Cursos asociados</Typography>
        </Grid>

        <Grid xs={12} lg={12}>
          {
            moodle && <CourseTable registries={[...editContext?.record?.userCourseMoodle].reverse()} />
          }
        </Grid>
      </Grid>
    </SimpleForm >
  );
};


function CourseTable({ registries }) {
  console.log(registries)
  return (
    registries && (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">id</TableCell>
              <TableCell align="left">fullname</TableCell>
              <TableCell align="left">avance</TableCell>
              <TableCell align="left">Inicio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registries.map((reg) => (
              <TableRow key={reg.id}>
                <TableCell component="th" scope="row">
                  {reg.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {reg.fullname}
                </TableCell>
                <TableCell component="th" scope="row">
                  {reg.progress}
                </TableCell>
                <TableCell component="th" scope="row">
                  {new Date(reg.startdate).toString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}


export default UserForm;
