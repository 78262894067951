import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useCallback } from "react";
import { TextField, DatagridConfigurable, List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput, BooleanInput, DateInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <SortButton fields={['dateRequestAction']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const sectorFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="dateRequestAction" />,
];

// const Empty = () => (
//     <Box textAlign="center" m={1}>
//         <Typography variant="h4" paragraph>
//             No Company available
//         </Typography>
//         <Typography variant="body1">
//             Create one
//         </Typography>
//         <CoreCreateButton permission="BACKOFFICE_REQUEST_FOR_ACTON_C" />
//     </Box>
// );

const TabbedDatagrid = () => {
    const listContext = useListContext();
    // const { filterValues, setFilters, displayedFilters } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="dateRequestAction" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}

const AnalysisOfSharesList = () => {
    const getResourceLabel = useGetResourceLabel();
    return (<List
        // filterDefaultValues={ { active: true }}
        sort={{ field: 'dateRequestAction', order: 'DESC' }}
        perPage={25}
        filters={sectorFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default AnalysisOfSharesList;

