import { Datagrid, Loading, RaRecord, TextField, useListContext, useTranslate } from "react-admin";
import { Sector } from "../../../shared/types/types";
import CoreEditButton from "../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../shared/components/core/CoreDeleteButton";
import sacopModules from "../../../shared/utilities/sacopModules";
import NoDataFound from "../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<Sector>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) 
    {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />; 
    }
    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="ahkDus"  variant="body1" />
            <TextField source="customHouse" variant="body1" />
            
            <CoreEditButton permission="BACKOFFICE_LOCALIZATION_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_LOCALIZATION_D" />
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
