import {
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { Dispatch, SetStateAction, useState } from "react";

import { ChapterNode } from "../../types/types";
import UploadFileButton from "../buttons/UploadFileButton";
import Registries from "./Registries";
import VersionTable from "./VersionTable";
import ChapterButtons from "./chapterButtons/ChapterButtons";
import { useTranslate } from "react-admin";

interface TreeActionsProps {
  currentNode: ChapterNode;
  setCurrentNode: Dispatch<SetStateAction<ChapterNode>>;
  chapterTreeId: string;
  handleModification: () => any;
}

interface InputData {
  name?: string;
  docFile?: File;
  code?: string;
  active?: boolean;
  code_version?: string;
}

const cleanInputData = {
  name: "",
  code: "",
  docFile: undefined,
};

export function TreeActions({
  currentNode,
  chapterTreeId,
  handleModification,
  setCurrentNode,
}: TreeActionsProps) {
  const [inputData, setInputData] = useState<InputData>({
    name: currentNode.name,
    code: currentNode.code,
    active: currentNode.active,
    code_version: currentNode.code_version,
    docFile: undefined,
  });
  const [updateButtonClicked, setUpdateButtonClicked] =
    useState<boolean>(false);
  
  const translate = useTranslate();

  const handleTreeChange = () => {
    handleModification();
    setInputData(cleanInputData);
    setCurrentNode({
      id: "",
      name: "",
      code: "",
      type: "",
      active: false,
      childrens: undefined,
      code_version: ""
    });
  };

  return (
    <>
        <Typography variant="h6" color="primary">
        {translate("resources.normative.chapter.fields.admin.structure.bed")}
        </Typography>
        <Grid container xs={12} spacing={2}>
          <Grid xs={12} lg={6}>
            <TextField
              disabled={!updateButtonClicked}
              value={inputData.code}
              label={translate("resources.normative.chapter.fields.code")}
              onChange={(e) => {
                setInputData({ ...inputData, code: e.target.value });
              }}
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              disabled={!updateButtonClicked}
              value={inputData.name}
              label={translate("resources.normative.chapter.fields.name")}
              onChange={(e) => {
                setInputData({ ...inputData, name: e.target.value });
              }}
            />
          </Grid>
          <Grid xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!updateButtonClicked}
                    checked={inputData.active}
                    onChange={(e) =>
                      setInputData({ ...inputData, active: e.target.checked })
                    }
                  />
                }
                label={translate("resources.normative.chapter.fields.active")}
              />
            </FormGroup>
          </Grid>
          {currentNode && currentNode.type == "f" && (
            <Grid xs={12}>
              <UploadFileButton
                file={inputData?.docFile}
                disabled={!updateButtonClicked}
                size="small"
                component="label"
                variant="outlined"
                handleChange={(e) =>
                  e.target.files &&
                  setInputData({ ...inputData, docFile: e.target.files[0] })
                }
                innerText={translate("resources.normative.chapter.fields.new_version")}
              />
              <TextField
              disabled={!updateButtonClicked}
              value={inputData.code_version}
              label={translate("resources.normative.chapter.fields.code_version")}
              onChange={(e) => {
                setInputData({ ...inputData, code_version: e.target.value });
              }}
            />
            </Grid>
          )}
          {currentNode.type === "f" && (
            <Grid xs={12}>
              <VersionTable versions={[...currentNode.versions!].reverse()} />
            </Grid>
          )}
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <ChapterButtons
              chapterTreeId={chapterTreeId}
              updateButtonClicked={updateButtonClicked}
              setUpdateButtonClicked={setUpdateButtonClicked}
              currentNode={currentNode}
              handleTreeChange={handleTreeChange}
              inputData={inputData}
              setInputData={setInputData}
            />
          </Grid>
        </Grid>
    </>
  );
}
