
const sacopModules = {
    REQUESTFORACTION: "RequestForAction",
    ANALYSISFORSHARES: "AnalysisForShares",
    IMPLEMENTATIONFORACTION: "ImplementationForAction",
    CLOSINGFORSHARES: "ClosingForShares",
    VERIFICATIONEFFECTIVENESSACTION: "VerificationEffectivenessAction",
    MASTEROFSHARES: "MasterOfShares",
    NONE: "none",
};
  
export default sacopModules;