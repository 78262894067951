import { useState, Fragment, Dispatch, SetStateAction } from "react";

import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderIcon from "@mui/icons-material/Folder";
import { ChapterNode } from "../../types/types";
import { truncateN } from "../../utilities/truncate";
import { InsertDriveFile } from "@mui/icons-material";

interface TreeProps {
  chapterRoot: ChapterNode;
  currentNode: ChapterNode;
  setCurrentNode: Dispatch<SetStateAction<ChapterNode>>;
}

export function Tree({
  chapterRoot,
  currentNode,
  setCurrentNode,
}: TreeProps) {
  const [openChapter, setOpenFolder] = useState({});

  const handleClick = (chapter: ChapterNode) => {
    setOpenFolder((prevState) => ({
      [chapter.id]: !prevState[chapter.id],
    }));

    const currentChapter = { ...chapter };
    // currentChapter.childrens = undefined;
    setCurrentNode(currentChapter);
  };

  const FILENAME_TRUNCATE_N = 35;


  // chapterRoot is a directory with no childrens or is a file type
  if (chapterRoot.type == "f") {
    return (
      <div key={chapterRoot.id}>
        <ListItem
          button
          onClick={() => handleClick(chapterRoot)}
          selected={currentNode.id === chapterRoot.id}
        >
          <InsertDriveFile />
          <ListItemText
            sx={{ ml: 1 }}
            primary={truncateN("(".concat(chapterRoot.code.concat(") - ")).concat(chapterRoot.name), FILENAME_TRUNCATE_N)}
          />
        </ListItem>
      </div>
    );
  }

  // Recursively render each children
  return (
    <div key={chapterRoot.id} style={{ maxHeight: 'auto', overflowY: 'auto' }}>
      <ListItem
        button
        onClick={() => handleClick(chapterRoot)}
        selected={currentNode.id === chapterRoot.id}
      >
        {openChapter[chapterRoot.code] ? (
          <FolderOpenIcon sx={{ color: "primary.main" }} />
        ) : (
          <FolderIcon sx={{ color: "primary.light" }} />
        )}
        <ListItemText
          sx={{ ml: 1 }}
          primary={truncateN("(".concat(chapterRoot.code.concat(") - ")).concat(chapterRoot.name), FILENAME_TRUNCATE_N)}
        />
        {openChapter[chapterRoot.id] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openChapter[chapterRoot.id]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ paddingLeft: "10px" }}>
          {chapterRoot.childrens &&
            chapterRoot.childrens
              .sort()
              .map((children) => {
                return (
                  <Tree
                    setCurrentNode={setCurrentNode}
                    chapterRoot={children}
                    currentNode={currentNode}
                  />
                );
              })}
        </List>
      </Collapse>
    </div>
  );
}
