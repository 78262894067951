import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    TextInput,
    required,
    SelectInput,
    SimpleForm,
    DateInput,
    Loading,
    FileInput,
    FileField,
    BooleanInput,
    useRecordContext,
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { SacopStatus, User } from "../../../../shared/types/types";
import fetchDataService from "../../../../shared/utilities/fetchs";


interface VerificationEffectivenessFormProps {
    edit?: boolean;
    formTitle: ReactElement;
}

const VerificationEffectivenessActionForm = ({ edit, formTitle }: VerificationEffectivenessFormProps) => {
    const [loading, setLoading] = useState(true);
    const [sacopStatusVerification, setSacopStatusVerification] = useState<SacopStatus[]>(); 
    const [showVerificationStatusField, setShowVerificationStatusField ] = useState(false);
    const record = useRecordContext();

    const handleBooleanInput = (event: any) => {
        setShowVerificationStatusField(event.target.checked);
    };

    useEffect(() => {
        setShowVerificationStatusField(record.showVerificationStatus);
    }, [record]);

    useEffect(() => {
        const { getSacopStatusForVerificationData} = fetchDataService();
        Promise.all([
            getSacopStatusForVerificationData(),
        ]).then(([sacopStatusVerificationResponse]) => {
            setSacopStatusVerification(sacopStatusVerificationResponse.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data in Implementation:', error);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <SimpleForm
            toolbar={<FormToolbar edit={edit ? true : undefined} />}
            sx={{ mt: 3, mb: 3 }}
        >
            <Grid container lg={10} lgOffset={1} columnSpacing={2}>
                <Grid xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h6">{formTitle}</Typography>
                </Grid>
                <Grid container md={12}>
                    <Grid xs={12} md={4}>
                        <TextInput
                            source="responsibleForVerification.fullName"
                            label="resources.sacop.fields.responsibleForVerificationId"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <DateInput 
                        source="verificationDate"
                        label="resources.sacop.fields.verificationDate"
                        variant="outlined"
                        validate={required()}
                        helperText={false}
                        fullWidth
                    />
                    </Grid> 
                    {showVerificationStatusField && (
                        <Grid xs={12} md={4}>
                            <SelectInput 
                                source="verificationStatusId"
                                label="resources.sacop.fields.verificationStatusId"
                                fullWidth
                                // validate={required()}
                                choices={sacopStatusVerification?.map(item => ({ id: item.id, name: item.statusName }))}
                            />
                        </Grid>
                    )}
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="verificationObservations"
                            label="resources.sacop.fields.verificationObservations"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid xs={12} md={2}>
                        <BooleanInput
                            autoFocus
                            source="showVerificationStatus"
                            label="resources.sacop.fields.showVerificationStatus"
                            fullWidth
                            onChange={handleBooleanInput}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </SimpleForm>
    );
    
};

export default VerificationEffectivenessActionForm;