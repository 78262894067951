import ProductIcon from '@mui/icons-material/Collections';
import LaboratoryList from './LaboratoryList';
import LaboratoryCreate from './LaboratoryCreate';
import LaboratoryEdit from './LaboratoryEdit';

export default {
    list: LaboratoryList,
    create: LaboratoryCreate,
    edit: LaboratoryEdit,
    icon: ProductIcon,
};
