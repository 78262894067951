import {
    BooleanField,
    Datagrid,
    DateField,
    Loading,
    RaRecord,
    TextField,
    useListContext,
    useTranslate,
} from "react-admin";
import { LaboratoryRegistry } from "../../../shared/types/types";
import CoreEditButton from "../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../shared/components/core/CoreDeleteButton";
import sacopModules from "../../../shared/utilities/sacopModules";
import NoDataFound from "../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {
    const { data, isLoading } = useListContext<LaboratoryRegistry>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />;
    }
    return (
        <Datagrid bulkActionButtons={false}>
            <TextField
                label={translate("resources.laboratory_registry.fields.oi_name")}
                source="oi.name"
                variant="body1"
                sortable={false}
            />
            <TextField
                label="resources.laboratory_registry.fields.quality_report.fields.number"
                source="qualityReport.number"
                variant="body1"
                sortable={false}
            />
            <TextField
                label={translate(
                    "resources.operation_registry.fields.custom_house"
                )}
                source="operationRegistry.customHouse.name"
                variant="body1"
                sortable={false}
            />
            <DateField
                label={translate("resources.operation_registry.fields.testing_lab_reception_date")}
                source="operationRegistry.testingLabReceptionDate"
                variant="body1"
                sortable={false}
            />
            <TextField
                label={translate("resources.operation_registry.fields.exporter")}
                source="operationRegistry.exporter.name"
                variant="body1"
                sortable={false}
            />
            <CoreEditButton permission="BACKOFFICE_LABORATORY_REGISTRY_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_LABORATORY_REGISTRY_D" />
        </Datagrid>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
