import buildRestProvider from "../shared/utilities/simpleRestProvider"

import {
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
} from "react-admin";
import { Sacop } from "../shared/types/types";

const fetchJson = (url, options: any = {}) => {
    const correlationId = process.env.REACT_APP_CORRELATION_ID;
    const appId = process.env.REACT_APP_ID;
    const authBearerToken = localStorage.getItem("auth")?.toString();

    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    // add your own headers here
    options.headers.set("X-Correlation-Id", correlationId);
    options.headers.set("X-App-Id", appId);
    options.headers.set("Authorization",`Bearer ${authBearerToken}`);
    return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = buildRestProvider(
    String(process.env.REACT_APP_API_URL),
    fetchJson
);

const createPostFormData = async (params: CreateParams<Sacop> | UpdateParams<Sacop>) => {
    const requestForActionFormData = new FormData();
    const requestForAction = params.data;

    if (requestForAction.evidenceOfRequest) {
        if (requestForAction.evidenceOfRequest.rawFile) {
            requestForActionFormData.append(
                "evidenceOfRequest", requestForAction.evidenceOfRequest.rawFile!
            );
        } else {
            const res = await fetch(requestForAction.evidenceOfRequest.src!);
            const data = await res.blob();
            requestForActionFormData.append(
                "evidenceOfRequest", new File([data], requestForAction.evidenceOfRequest.title!, {type: res.headers.get("content-type")!})
            );
        }
    }

    requestForActionFormData.append(
        "requestForActionInfo",
        new Blob([JSON.stringify(requestForAction)], {
            type: "application/json",
        })
    );

  return requestForActionFormData;
};

export const dataProvider: DataProvider = {
    ...baseDataProvider,
    create: async (resource, params) => {
        if (resource === "requestForAction") {
            console.log('Se llama al metodo create de requestForAction');
            const formData = await createPostFormData(params);
            return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}`, {
                method: "POST",
                body: formData,
            }).then(({ json }) => ({ data: json }));
        }
        return baseDataProvider.create(resource, params);
    },
    update: async (resource, params) => {
        if (resource === "requestForAction") {
            console.log('Se llama al metodo update de requestForAction');
            const formData = await createPostFormData(params);
            return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,{
                method: "PUT",
                body: formData,
            }).then(({ json }) => ({ data: json }));
        }
        return baseDataProvider.update(resource, params);
    },
};
