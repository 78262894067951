import {
  TabbedForm,
  TextInput,
  BooleanInput,
  required,
  Loading,
  useGetList,
  SelectInput,
  CheckboxGroupInput,
  SimpleForm,
  AutocompleteInput,
} from "react-admin";
import AddressInput from "../../../shared/components/direction/AddressArrayInput";
import { Container, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import FormToolbar from "../../../shared/components/toolbar/FormToolbar";
import useUser from "../../../shared/hooks/useUsers";

interface LocalizationFormProps {
  formTitle: string;
  edit?: boolean;
}

const LocalizationForm = ({ formTitle, edit }: LocalizationFormProps) => {
  const {
    data: dataNormative,
    total,
    isLoading,
    error,
  } = useGetList("normative", {
    filter: { active: true },
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "DESC" },
  });

  const { dataUser, isLoadingUser } = useUser();


  if (isLoadingUser) {
    return <Loading />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 2 }}
      defaultValues={{ id: "0000-0000-0000-0000" }}
    >
      <Grid
        container
        sx={{ width: "100%" }}
        lg={10}
        lgOffset={1}
        columnSpacing={2}
      >
        <Grid xs={12} sx={{ mb: 2 }}>
          <Typography variant="h6">{formTitle.toUpperCase()}</Typography>
        </Grid>
        <Grid md={4}>
          <TextInput
            autoFocus
            source="code"
            label="resources.localization.fields.code"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid md={4}>
          <TextInput
            autoFocus
            source="name"
            label="resources.localization.fields.name"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid md={4}>
          <AutocompleteInput
            source="userInfo.id"
            label="resources.localization.fields.user"
            choices={
              dataUser
                ? dataUser.map((user) => ({
                    id: user.mail,
                    name: user.mail,
                  }))
                : []
            }
            fullWidth
          />
        </Grid>
        <Grid md={4}>
          <TextInput
            autoFocus
            source="description"
            label="resources.localization.fields.description"
            fullWidth
            multiline
          />
        </Grid>
        <Grid md={4}>
          <TextInput
            autoFocus
            source="phone"
            label="resources.localization.fields.phone"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid md={4}>
          <BooleanInput
            autoFocus
            source="active"
            label="resources.localization.fields.active"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} sx={{ mb: 2 }}>
          <Divider />
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12} md={6}>
            <Typography variant="h6">Dirección</Typography>
            <AddressInput fullWidth />
          </Grid>
          <Grid xs={12} md={6}>
            <Typography variant="h6">Normativas Asociadas</Typography>
            <CheckboxGroupInput
              label=""
              source="normatives"
              choices={dataNormative}
            />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default LocalizationForm;
