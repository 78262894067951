import VerificationInput from "react-verification-input";
import { Button, Stack, Typography } from '@mui/material';
import fetchDataService from "../../../../../shared/utilities/fetchs";
import { useNavigate } from "react-router-dom";
import { useNotify, useTranslate } from "react-admin";
import { useState } from "react";

const CODE_LENGTH = 6;

export const CODE_SETUP_TYPE = {
    INIT_SETUP: 0,
    CODE_SETUP: 1,
    RECOVERY_CODE: 2,
}

interface MfaCodeSetupProps {
    initSetup: boolean;
    show: boolean;
}

const MfaCodeSetup = ({ initSetup, show } : MfaCodeSetupProps) => {
    const translate = useTranslate();
    const { postValidateMfaSetup, postMfaLoginCode } = fetchDataService();
    const navigate = useNavigate();
    const notify = useNotify();
    const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);

    const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);
    const message = 
        initSetup 
            ?
                translate("resources.menu.security.mfa.fields.setup.code.init_setup")
            :
                translate("resources.menu.security.mfa.fields.setup.code.login");

    const execute = (func: (code: string) => Promise<any>, code: string) => {
        func(code).then((result => {
            const data = result.data.data;
            localStorage.setItem('auth', data.accessToken);

            if (initSetup) {
                notify(translate("resources.menu.security.mfa.fields.setup.code.setup_success"), { type: 'success' });
                setShowRecoveryCodes(true);
                setRecoveryCodes(result.data.data.recoveryCodes);
            } else {
                navigate('/#', { replace: true });
            }

        }))
        .catch((error) => {
            console.error(`Error => ${error}`)
            notify(translate("resources.menu.security.mfa.fields.setup.code.invalid"), { type: 'error' });
        });
    }

    const sendCode = (code: string) => {
        if (initSetup) {
            execute(postValidateMfaSetup, code);
        } else {
            execute(postMfaLoginCode, code);
        }
    }

    return (
        <>
            {show 
                ?
                <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center" spacing={2}>
                    {!showRecoveryCodes &&
                        <>
                            <Typography variant="body1">
                                {message}
                            </Typography>
                            <VerificationInput
                                length={CODE_LENGTH}
                                validChars="0-9"
                                onComplete={(code) => sendCode(code)}
                            />
                        </>
                    }
                    {initSetup && !showRecoveryCodes &&
                        <Stack width="100%" display="flex" alignItems="end" justifyContent="end">
                            <Button variant="contained" onClick={() => navigate('/security', { replace: true })}>{translate("resources.menu.security.mfa.fields.btns.cancel_operation")}</Button>
                        </Stack>
                    }
                    {showRecoveryCodes &&
                        <>
                            <Typography variant="h5">
                                {translate("resources.menu.security.mfa.fields.recovery_codes.name")}
                            </Typography>
                            <Typography variant="body1">
                                {translate("resources.menu.security.mfa.fields.recovery_codes.first_message")} <strong>{translate("resources.menu.security.mfa.fields.recovery_codes.second_message")}</strong> 
                                {translate("resources.menu.security.mfa.fields.recovery_codes.third_message")}
                            </Typography>
                            <Stack display="flex" flexDirection="row" justifyContent="center" gap={10}>
                                {recoveryCodes.map((code, index) => {
                                    return (
                                        <Typography variant="h6">
                                            {index + 1}) {code}
                                        </Typography>
                                    )
                                })}
                            </Stack>
                            <Stack display="flex" flexDirection="row" justifyContent="end">
                                <Button variant="contained" onClick={() => navigate('/security', { replace: true })}>{translate("resources.menu.security.mfa.fields.btns.continue_operation")}</Button>
                            </Stack>
                        </>
                    }
                </Stack>
                : null
            }
        </>
    )
}

export default MfaCodeSetup;