import { Fragment, useCallback } from "react";
import {
    TopToolbar,
    SortButton,
    FilterButton,
    ExportButton,
    useListContext,
    DatagridConfigurable,
    TextField,
    List,
    SearchInput,
    TextInput,
    useGetResourceLabel,
} from "react-admin";

import { Theme, useMediaQuery } from "@mui/material";

import CoreCreateButton from "../../../shared/components/core/CoreCreateButton";
import MobileGrid from "./MobileGrid";

const sectorFilters = [
    <SearchInput source="q" alwaysOn />,

    <TextInput source="name" />
];

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_NORMATIVE_C" />
        <SortButton fields={["ahkDus"]} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="ahkDus" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}

export default function RegisterFormList() {
    const getResourceLabel = useGetResourceLabel();
    return (<List
        sort={{ field: 'ahkDus', order: 'DESC' }}
        perPage={25}
        filters={sectorFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
}
