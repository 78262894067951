import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";

export default function ModifyChapterButton({
  setUpdateButtonClicked,
  updateButtonClicked,
  currentNodeEmpty,
}) {
  return (
    <Button
      sx={{mr: 1}}
      onClick={(e) => setUpdateButtonClicked(!updateButtonClicked)}
      variant="outlined"
      startIcon={<Edit />}
      disabled={currentNodeEmpty}
    >
      Modificar
    </Button>
  );
}
