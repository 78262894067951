import {
  AutocompleteInput,
  BooleanInput,
  email,
  Loading,
  required,
  SimpleForm,
  TabbedForm,
  TextInput,
  useGetList,
} from "react-admin";
import AddressInput from "../../../../shared/components/direction/AddressArrayInput";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Divider, Typography } from "@mui/material";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import useUser from "../../../../shared/hooks/useUsers";

interface ExporterFormProps {
  edit?: boolean;
  formTitle: string;
}

export default function ExporterForm({ edit, formTitle }: ExporterFormProps) {
  const { dataUser, isLoadingUser } = useUser();

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 3 }}
    >
      <Grid container lg={10} lgOffset={1} columnSpacing={2}>
        <Grid xs={12} sx={{ mb: 2 }}>
          <Typography variant="h6">{formTitle.toUpperCase()}</Typography>
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput source="name" label="resources.exporter.fields.name" validate={required()}/>
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="taxpayerReference"
            label="resources.exporter.fields.taxpayer_reference"
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="contactEmail"
            label="resources.exporter.fields.contact_email"
            validate={email()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="contactPhone"
            label="resources.exporter.fields.contact_phone"
            // validate={regex()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <AutocompleteInput
            fullWidth
            source="ahkContactId"
            choices={
              dataUser
                ? dataUser.map((user) => ({
                    id: user.mail,
                    name: user.mail,
                  }))
                : []
            }
          />
        </Grid>
        <Grid xs={12} md={6}>
          <BooleanInput source="active" />
        </Grid>
        <Grid xs={12}>
          <Divider sx={{mb: 2}}/>
          <Typography sx={{mb:1}} variant="h6">Direcciones</Typography>
          <AddressInput />
        </Grid>
      </Grid>
    </SimpleForm>
  );
}
