import ProductIcon from '@mui/icons-material/Collections';
import CustomsHouseList from './CustomsHouseList';
import CustomsHouseCreate from './CustomsHouseCreate';
import CustomsHouseEdit from './CustomsHouseEdit';

export default {
    list: CustomsHouseList,
    create: CustomsHouseCreate,
    edit: CustomsHouseEdit,
    icon: ProductIcon,
};
