import ProductIcon from '@mui/icons-material/Collections';
import WeightReportOperationList from './WeightReportOperationList';
import WeightReportOperationCreate from './WeightReportOperationCreate';
import WeightReportOperationEdit from './WeightReportOperationEdit';

export default {
    list: WeightReportOperationList,
    create: WeightReportOperationCreate,
    edit: WeightReportOperationEdit,
    icon: ProductIcon,
};
