import ProductIcon from '@mui/icons-material/Collections';
import LocalizationList from './LocalizationList';
import LocalizationCreate from './LocalizationCreate';
import LocalizationEdit from './LocalizationEdit';

export default {
    list: LocalizationList,
    create: LocalizationCreate,
    edit: LocalizationEdit,
    icon: ProductIcon,
};
