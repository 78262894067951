import buildRestProvider from "../shared/utilities/simpleRestProvider"
import axios from "axios"

import {
  CreateParams,
  UpdateParams,
  DataProvider,
  fetchUtils,
} from "react-admin";
import { Instrument } from "../shared/types/types";

const fetchJson = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("X-Correlation-Id", "foobar");
  options.headers.set("X-App-Id", "00e9396e-6c63-4834-8492-a1db4edc6421");
  options.headers.set(
    "Authorization",
    `Bearer ${localStorage.getItem("auth")?.toString()}`
  );
  return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = buildRestProvider(
  String(process.env.REACT_APP_API_URL),
  fetchJson
);

const createPostFormData = async (
  params: CreateParams<Instrument> | UpdateParams<Instrument>
) => {
  const instrumentFormData = new FormData();

  const instrument = params.data;

  if (instrument.calibration?.certcalibration) {
      if (instrument.calibration?.certcalibration.rawFile) {
        instrumentFormData.append("certcalibration", instrument.calibration?.certcalibration.rawFile!);
      } else {
        const res = await fetch(instrument.calibration?.certcalibration.src!);
        const data = await res.blob();
        instrumentFormData.append("certcalibration", new File([data], instrument.calibration?.certcalibration.title!, {type: res.headers.get("content-type")!}));
      }
  }

  instrumentFormData.append(
    "instrumentInfo",
    new Blob([JSON.stringify(instrument)], {
      type: "application/json",
    })
  );

  return instrumentFormData;
};

export const dataProvider: DataProvider = {
  ...baseDataProvider,
  create: async (resource, params) => {
    if (resource === "instrument") {
      const formData = await createPostFormData(params);
      return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({ data: json }));
    }
    return baseDataProvider.create(resource, params);
  },
  update: async (resource, params) => {
    if (resource === "instrument") {
      const formData = await createPostFormData(params);
      return fetchJson(
        `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
        {
          method: "PUT",
          body: formData,
        }
      ).then(({ json }) => ({ data: json }));
    }
    return baseDataProvider.update(resource, params);
  },
};
