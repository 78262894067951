import ProductIcon from '@mui/icons-material/Collections';
import ImplementationOfActionShow from './ImplementationOfActionShow';
import ImplementationOfActionEdit from './ImplementationOfActionEdit';
import ImplementationOfActionList from './ImplementationOfActionList';

export default {
    list: ImplementationOfActionList,
    edit: ImplementationOfActionEdit,
    icon: ProductIcon,
    show: ImplementationOfActionShow,
};