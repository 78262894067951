import { Create, useNotify, useTranslate } from "react-admin";
import ExporterForm from "./ExporterForm";

export default function ExporterCreate() {
    const notify = useNotify();
    const translate = useTranslate();

    const onError = (error) => {
        notify(translate("resources.exporter.errors.taxpayer_reference"), {
            type: "error",
        });
    };

    return (
        <Create mutationOptions={{ onError }}>
            <ExporterForm formTitle="crear exportador"/>
        </Create>
    );
}
