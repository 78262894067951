import { Create } from "react-admin";
import WeightReportOperationForm from "./WeightReportOperationForm";

const WeightReportOperationCreate = () => (
    <Create>
        <WeightReportOperationForm />
    </Create>
);

export default WeightReportOperationCreate;
