import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useCallback } from "react";
import { TextField, DatagridConfigurable, List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput, BooleanInput, DateInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        {/* <CoreCreateButton permission="BACKOFFICE_IMPLEMENTATION_OF_ACTION_C" /> */}
        <SortButton fields={['dateRequestAction']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

//TODO: agregar mas atributos para filtrar los registros.
const sectorFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="dateRequestAction" />,
    // <BooleanInput source="active" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    // const { filterValues, setFilters, displayedFilters } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="dateRequestAction" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}

const ImplementationOfActionList = () => {
    const getResourceLabel = useGetResourceLabel();
    return (<List
        // filterDefaultValues={ { active: true }}
        sort={{ field: 'dateRequestAction', order: 'DESC' }}
        perPage={25}
        filters={sectorFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default ImplementationOfActionList;

