import buildRestProvider from "../shared/utilities/simpleRestProvider"

import {
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
} from "react-admin";
import { Sacop } from "../shared/types/types";

const fetchJson = (url, options: any = {}) => {
    const correlationId = process.env.REACT_APP_CORRELATION_ID;
    const appId = process.env.REACT_APP_ID;
    const authBearerToken = localStorage.getItem("auth")?.toString();

    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    // add your own headers here
    options.headers.set("X-Correlation-Id", correlationId);
    options.headers.set("X-App-Id", appId);
    options.headers.set("Authorization",`Bearer ${authBearerToken}`);
    return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = buildRestProvider(
    String(process.env.REACT_APP_API_URL),
    fetchJson
);

const createPostFormData = async (params: CreateParams<Sacop> | UpdateParams<Sacop>) => {
    const implementationForActionFormData = new FormData();
    const implementationForAction = params.data;

    if (implementationForAction.evidence) {
        if (implementationForAction.evidence.rawFile) {
            implementationForActionFormData.append(
                "evidence", implementationForAction.evidence.rawFile!
            );
        } else {
            const res = await fetch(implementationForAction.evidence.src!);
            const data = await res.blob();
            implementationForActionFormData.append(
                "evidence", new File([data], implementationForAction.evidence.title!, {type: res.headers.get("content-type")!})
            );
        }
    }

    implementationForActionFormData.append(
        "implementationOfActionInfo",
        new Blob([JSON.stringify(implementationForAction)], {
            type: "application/json",
        })
    );

  return implementationForActionFormData;
};

export const dataProvider: DataProvider = {
    ...baseDataProvider,
    // create: async (resource, params) => {
    //     if (resource === "implementationOfAction") {
    //         console.log('Se llama al metodo create de implementationOfAction');
    //         const formData = await createPostFormData(params);
    //         return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}`, {
    //             method: "POST",
    //             body: formData,
    //         }).then(({ json }) => ({ data: json }));
    //     }
    //     return baseDataProvider.create(resource, params);
    // },
    update: async (resource, params) => {
        if (resource === "implementationOfAction") {
            console.log('Se llama al metodo update de implementationOfAction');
            const formData = await createPostFormData(params);
            return fetchJson(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,{
                method: "PUT",
                body: formData,
            }).then(({ json }) => ({ data: json }));
        }
        return baseDataProvider.update(resource, params);
    },
};
