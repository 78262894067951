import { Button } from "@mui/material";
import fetchDataService from "../../utilities/fetchs";
import { useNotify, useTranslate } from "react-admin";
import { Save } from "@mui/icons-material";

export default function SaveChapterButton({
  chapterTreeId,
  currentNode,
  handleTreeChange,
  setUpdateButtonClicked,
  inputData,
}) {
  const notify = useNotify();
  const translate = useTranslate();
  const handleUpdate = async (e) => {
  const { putChapterTreeData, putMultipartChapterTreeData } =
      fetchDataService();
    const chapterTreeInfo = {
      id: chapterTreeId,
      root: {
        ...currentNode,
        ...inputData
      },
      chapterNodeId: currentNode.id,
    };
    putMultipartChapterTreeData(chapterTreeId, {
      chapterTreeInfo,
      docFile: inputData.docFile,
    }).then((res) => {
      handleTreeChange();
      setUpdateButtonClicked(false);
      notify(translate("ra.notification.updated", { smart_count: 1 }), {
        type: "info",
      });
    });
  };
  return (
    <Button startIcon={<Save />} variant="contained" onClick={handleUpdate}>
      Aplicar
    </Button>
  );
}
