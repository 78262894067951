import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Port } from "../../../../shared/types/types";
import PortForm from "./PortForm";
import {truncate} from "../../../../shared/utilities/truncate";

const PortEdit = () => (
    <Edit title={<PortTitle />}component="div">
        <PortForm edit formTitle="editar puerto" />
    </Edit>
);

const PortTitle = () => {
    const portRecord = useRecordContext<Port>();
    const translate = useTranslate();
    return portRecord ? (
        <span>
            {`${translate("resources.port.title")} ${truncate(portRecord.name)}`}
        </span>
    ) : null;
};

export default PortEdit;
