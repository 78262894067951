import {
  BooleanField,
  Datagrid,
  Loading,
  RaRecord,
  TextField,
  useListContext
} from "react-admin";
import { Localization } from "../../../shared/types/types";
import CoreEditButton from "../../../shared/components/core/CoreEditButton";
import sacopModules from "../../../shared/utilities/sacopModules";
import { Label } from "@mui/icons-material";
import NoDataFound from "../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
  data?: RaRecord[];
}




const MobileGrid = (props: MobileGridProps) => {
  const { data: localizationData, isLoading } = useListContext<Localization>();
  if (isLoading || localizationData.length === 0) {
    if (localizationData?.length === 0){
      return <NoDataFound />
    }
    return <Loading />;
  }

  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="code" variant="body1" />
      <TextField source="name" variant="body1" />
      <TextField source="userInfo.id" variant="body1" sortable={false}/>
      <TextField source="normativeChain" variant="body1" sortable={false}/>
      <TextField source="phone" variant="body1" />
      <BooleanField source="active" variant="body1" sortable={false}/>

      <CoreEditButton permission="BACKOFFICE_LOCALIZATION_U" actionModule={sacopModules.NONE} />
    </Datagrid>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
