import ProductIcon from '@mui/icons-material/Collections';
import ExporterList from './ExporterList';
import ExporterCreate from './ExporterCreate';
import ExporterEdit from './ExporterEdit';

export default {
    list: ExporterList,
    create: ExporterCreate,
    edit: ExporterEdit,
    icon: ProductIcon,
};
