import { Edit, useRecordContext, useTranslate } from "react-admin";
import { CustomsHouse } from "../../../../shared/types/types";
import CustomsHouseForm from "./CustomsForm";
import {truncate} from "../../../../shared/utilities/truncate";

const CustomsHouseEdit = () => (
    <Edit title={<CustomsHouseTitle />} component="div">
        <CustomsHouseForm edit formTitle="editar aduana" />
    </Edit>
);

const CustomsHouseTitle = () => {
    const customHouseRecord = useRecordContext<CustomsHouse>();
    const translate = useTranslate();
    return customHouseRecord ? (
        <span>
            {`${translate("resources.customs_house.title")} ${truncate(customHouseRecord.name)}`}
        </span>
    ) : null;
};


export default CustomsHouseEdit;
