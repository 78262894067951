import { Cancel } from "@mui/icons-material";
import { Button } from "@mui/material";

export default function CancelChapterButton ({
  setUpdateButtonClicked,
  resetInput,
  updateButtonClicked,
}) {
  return (
    <Button
      startIcon={<Cancel />}
      sx={{ ml: 1 }}
      variant="text"
      onClick={(e) => {
        setUpdateButtonClicked(!updateButtonClicked);
        resetInput();
      }}
    >
      Cancelar
    </Button>
  );
}
