import ProductIcon from '@mui/icons-material/Collections';
import ExportList from './ExportList';
import ExportCreate from './ExportCreate';
import ExportEdit from './ExportEdit';

export default {
    list: ExportList,
    create: ExportCreate,
    edit: ExportEdit,
    icon: ProductIcon,
};
