import { BooleanField, Datagrid, DateField, NumberField, RaRecord, TextField } from "react-admin";
import CoreShowButton from "../../../../shared/components/core/CoreShowButton";

interface MobileGridProps {
    data? : RaRecord[]; 
}

const MobileGrid = (props: MobileGridProps) => {
    return (   
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="resources.sacop.fields.id" variant="body1" />
            <TextField source="motive.motiveName" label="resources.sacop.fields.motive.motiveName" variant="body1" />
            <DateField source="dateRequestAction" label="resources.sacop.fields.dateRequestAction" variant="body1" />
            <DateField source="closeDate" label="resources.sacop.fields.closeDate" variant="body1" />
            <DateField source="dateLastUpdated" label="resources.sacop.fields.dateLastUpdated" variant="body1" />
            <BooleanField source="sendToResponsibleForAnalysis" label="resources.sacop.titles.analysisOfShares.shortTitle" variant="body1" />
            <BooleanField source="sendToResponsibleForImplementation" label="resources.sacop.titles.implementationOfAction.shortTitle" variant="body1" />
            <BooleanField source="sendToResponsibleForClosingOfActions" label="resources.sacop.titles.closingOfShares.shortTitle" variant="body1" />
            <BooleanField source="sendToResponsibleForVerificationEffectivenessAction" label="resources.sacop.titles.verificationEffectiveness.shortTitle" variant="body1" />
            <TextField source="closingStatus.statusName" label="resources.sacop.fields.actionStatus" variant="body1" />
            <TextField source="verificationStatus.statusName" label="resources.sacop.fields.verificationStatus" variant="body1" />
            <TextField source="responsible" label="resources.sacop.fields.responsible" variant="body1" />
        </Datagrid>
    );
}

MobileGrid.defaultProps = {
    data : {},
    ids : [],
};

export default MobileGrid;