import { Theme, useMediaQuery } from "@mui/material";
import { Fragment, useCallback, useContext } from "react";
import { TextField, DatagridConfigurable, List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_LABORATORY_REGISTRY_C" />
        <SortButton fields={['oi']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const laboratoryRegistryFilters = [
    <SearchInput source="q" alwaysOn />,

    <TextInput source="outsourcedAnalytes" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="operationRegistry.dus" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}
export default function LaboratoryRegistryList() {
    const getResourceLabel = useGetResourceLabel();

    return (<List
        sort={{ field: 'oi', order: 'DESC' }}
        perPage={25}
        filters={laboratoryRegistryFilters}
        actions={<ListActions />}
        title="resources.laboratory_registry.title"
    >
        <TabbedDatagrid />
    </List>);
}
