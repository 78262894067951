import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    TextInput,
    required,
    SelectInput,
    SimpleForm,
    DateInput,
    NumberInput,
    Loading,
    AutocompleteArrayInput,
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import { ReactElement, useEffect, useState } from "react";
import { LossValuation, User } from "../../../../shared/types/types";
import fetchDataService from "../../../../shared/utilities/fetchs";
import { useFormContext } from 'react-hook-form';

interface AnalysisOfSharesFormProps {
    edit? : boolean;
    formTitle : ReactElement;
    labelImpact : ReactElement;
}

interface customNumberInputValues {
    step: number;
    max: number;
    min: number;
}

interface customNumberInputProps {
    firstSource: string;
    secondSource: string;
    targetSource: string;
    label: string;
    isRequired: boolean;
};

const inputValues : customNumberInputValues = {
    step: 1,
    max: 3,
    min: 0,
}

const AnalysisOfSharesForm = ({ edit, formTitle, labelImpact }: AnalysisOfSharesFormProps) => {
    const [loading, setLoading] = useState(true);
    const [lossValuation, setLossValuation] = useState<LossValuation[]>();
    const [responsibleForImplementation, setResponsibleForImplementation] = useState<User[]>();
    const [teamMembers, setTeamMembers] = useState<any[]>();

    useEffect(() => {
        const 
        { 
            getLossValuationData, 
            getResponsibleForImplementationData,
            getResponsibleForAnalysisData
        } = fetchDataService();
        Promise.all([
            getLossValuationData(),
            getResponsibleForImplementationData(),
            getResponsibleForAnalysisData(),
        ]).then(([lossValuationResponse,
            responsibleForImplementationResponse,
            responsibleForAnalysisResponse,
        ]) => {
            setLossValuation(lossValuationResponse.data);
            setResponsibleForImplementation(responsibleForImplementationResponse.data);
            const teamMembersData = responsibleForAnalysisResponse.data.map(member => {
                return {
                    id: member.id,
                    name: `${member.fullName}`
                }
            });
            setTeamMembers(teamMembersData);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading />;
    }

    const CustomNumberInput = ({ firstSource, secondSource, targetSource, label, isRequired }: customNumberInputProps) => {
        const form = useFormContext();

        const getValueAsNumber = (newValue: any) : number => {
            return isNaN(newValue) ? 0 : parseInt(newValue);
        }

        const handleInput = (newValue: any) => {
            const firstValueAsNumber = getValueAsNumber(newValue);
            const secondValueSource = form.getValues(secondSource);
            const secondValueAsNumber = getValueAsNumber(secondValueSource);
            const targetSourceValue = firstValueAsNumber * secondValueAsNumber;
            
            form.setValue(targetSource, targetSourceValue);
        }

        return (
            <NumberInput
                source={firstSource}
                label={label}
                step={inputValues.step}
                max={inputValues.max}
                min={inputValues.min}
                fullWidth
                validate={isRequired ? required() : undefined}
                onChange={(event) => {
                    const newValue = event.target.value;
                    handleInput(newValue);
                }}
            />
        )
    }

    return (
        <SimpleForm
            toolbar={<FormToolbar edit={edit ? true : undefined} />}
            sx={{ mt: 3, mb: 3 }}
        >
            <Grid width="100%">
                <Stack spacing={1}>
                    <Typography variant="h6">{formTitle}</Typography>
                    <Stack direction="row" spacing={2}>
                        <TextInput
                            source="visibleCause"
                            label="resources.sacop.fields.visibleCause"
                            fullWidth
                            validate={required()}
                            multiline
                        />
                        <TextInput
                            source="causeOrigin"
                            label="resources.sacop.fields.causeOrigin"
                            fullWidth
                            validate={required()}
                            multiline
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextInput
                            source="rootCause"
                            label="resources.sacop.fields.rootCause"
                            fullWidth
                            validate={required()}
                            multiline
                        />
                        <TextInput
                            source="correctiveActions"
                            label="resources.sacop.fields.correctiveActions"
                            fullWidth
                            validate={required()}
                            multiline
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>{labelImpact}</Typography>
                        <Stack>
                            <Stack direction="row" spacing={2}>
                                <CustomNumberInput 
                                    firstSource="impact.c"
                                    secondSource="impact.a"
                                    targetSource="impact.ca"
                                    label="C"
                                    isRequired
                                />
                                <CustomNumberInput 
                                    firstSource="impact.a"
                                    secondSource="impact.c"
                                    targetSource="impact.ca"
                                    label="A"
                                    isRequired
                                />
                                <NumberInput
                                    source="impact.ca"
                                    label="CA"
                                    step={1}
                                    max={3}
                                    min={0}
                                    fullWidth
                                    disabled
                                />
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <CustomNumberInput 
                                firstSource="impact.s"
                                secondSource="impact.so"
                                targetSource="impact.sso"
                                label="S"
                                isRequired
                            />
                            <CustomNumberInput 
                                firstSource="impact.so"
                                secondSource="impact.s"
                                targetSource="impact.sso"
                                label="SO"
                                isRequired
                            />
                            <NumberInput
                                source="impact.sso"
                                label="SSO"
                                step={1}
                                max={3}
                                min={0}
                                fullWidth
                                disabled
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <SelectInput
                            source="valorizationId"
                            label="resources.sacop.fields.valorizationId"
                            fullWidth
                            validate={required()}
                            choices={lossValuation?.map(item => ({ id: item.id, name: item.lossValuationName }))}
                        />
                        <NumberInput
                            source="valuationAmount"
                            label="resources.sacop.fields.valuationAmount"
                            min={1}
                            fullWidth
                            validate={required()}
                        /> 
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <DateInput 
                            source="dateOfFormationOfWorkTeam"
                            label="resources.sacop.fields.dateOfFormationOfWorkTeam"
                            variant="outlined"
                            validate={required()}
                            helperText={false}
                            fullWidth
                        />
                        <SelectInput
                            source="responsibleForImplementationId"
                            label="resources.sacop.fields.responsibleForImplementationId"
                            fullWidth
                            validate={required()}
                            choices={responsibleForImplementation?.map(item => ({ id: item.id, name: item.fullName }))}
                        />
                    </Stack>
                    <AutocompleteArrayInput
                        source="workTeamMembers"
                        choices={teamMembers}
                        label="resources.sacop.fields.workTeamMembers"
                        validate={required()}
                        fullWidth
                    />
                    <TextInput
                        source="evaluationResult"
                        label="resources.sacop.fields.evaluationResult"
                        fullWidth
                        validate={required()}
                        multiline
                    />
                </Stack>
            </Grid>
        </SimpleForm>
    );
};

export default AnalysisOfSharesForm;