import ProductIcon from '@mui/icons-material/Collections';
import VerificationEffectivenessActionList from './VerificationEffectivenessActionList';
import VerificationEffectivenessActionEdit from './VerificationEffectivenessActionEdit';
import VerificationEffectivenessActionShow from './VerificationEffectivenessActionShow';

export default {
    list: VerificationEffectivenessActionList,
    edit: VerificationEffectivenessActionEdit,
    icon: ProductIcon,
    show: VerificationEffectivenessActionShow,
};