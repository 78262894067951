import { Fragment } from "react";
import {
  ArrayInput,
  FormDataConsumer,
  required,
  SelectInput,
  SelectInputProps,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import cities from "./cities";
import states from "./data";

const toChoices = (items) => {
  return items.map((item) => ({ id: item.id, label: item.label }));
};

const AddressArrayInput = (props: SelectInputProps) => {
  return (
    <ArrayInput label="" source="addresses">
      <SimpleFormIterator inline>
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => (
            <Fragment>
              <SelectInput
                source={getSource?.("state")}
                label="resources.company.fields.direction.state"
                fullWidth
                choices={toChoices(states)}
                validate={required()}
                optionValue="id"
                optionText="label"
              />

              <SelectInput
                source={getSource?.("colony")} // Will translate to "authors[0].role"
                label="resources.company.fields.direction.colony"
                fullWidth
                validate={required()}
                choices={
                  scopedFormData && scopedFormData.state
                    ? toChoices(cities[scopedFormData.state])
                    : []
                }
                optionValue="id"
                optionText="label"
                {...rest}
              />
            </Fragment>
          )}
        </FormDataConsumer>
        <TextInput
          source="street"
          label="resources.company.fields.direction.street"
          fullWidth
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default AddressArrayInput;
