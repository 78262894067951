import ProductIcon from '@mui/icons-material/Collections';
//import AgentList from './AgentList';
//import AgentCreate from './AgentCreate';
//import AgentEdit from './AgentEdit';

export default {
    //list: AgentList,
    //create: AgentCreate,
    //edit: AgentEdit,
    icon: ProductIcon,
};
