import { Add } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import fetchDataService from "../../../utilities/fetchs";
import { useState } from "react";
import ChapterModalForm from "../ChapterModalForm";

export default function AddChapterButton({
  currentNodeEmpty,
  chapterTreeId,
  currentNode,
  handleTreeChange,
}) {

  const handleAdd = async (e) => {
    setOpenModal(true);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <ChapterModalForm
        chapterTreeId={chapterTreeId}
        currentNode={currentNode}
        open={openModal}
        setOpen={setOpenModal}
        handleTreeChange={handleTreeChange}
      />
      <Button
        sx={{mr: 1}}
        startIcon={<Add />}
        variant={!currentNodeEmpty && currentNode.type === "d" ? "contained" : "outlined"}
        onClick={handleAdd}
      >
        Agregar
      </Button>
    </>
  );
}
