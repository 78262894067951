import { useState, useEffect } from "react";

import { Loading, useGetRecordId } from "react-admin";

import { Grid } from "@mui/material";
import List from "@mui/material/List";

import { Tree } from "./Tree";
import { ChapterNode, Normative } from "../../types/types";
import fetchDataService from "../../utilities/fetchs";
import { TreeActions } from "./TreeActions";
import ChapterTabs from "./ChapterTabs";

export default function BarTreeView() {
  const [normativeRecord, setNormativeRecord] = useState<Normative>({
    id: "",
    code: "",
    name: "",
    description: "",
    version: "",
    operative: false,
    active: false,
    chapterTree: undefined
  });

  const normativeId = useGetRecordId()
  const [currentNode, setCurrentNode] = useState<ChapterNode>({
    id: "",
    name: "",
    type: "",
    code: "",
    active: false,
    childrens: undefined,
    registries: undefined,
  });

  const [treeModification, setTreeModification] = useState(false);

  useEffect(() => {
    const { getNormativeData } = fetchDataService();
    getNormativeData(normativeId.toString()).then((res) => {
      setNormativeRecord(res.data)
    })
  }, [treeModification]);

  const handleTreeModification = () => {
    setTreeModification(!treeModification)
  }

  return (
    normativeRecord.chapterTree? (
    <Grid id="container" container>
      <Grid id="tree" xs={4}>
        <List sx={{ p: 2 }}>
          <Tree
            chapterRoot={normativeRecord.chapterTree!.root}
            currentNode={currentNode}
            setCurrentNode={setCurrentNode}
          />
        </List>
      </Grid>
      <Grid id="tree" xs={8}>
        <ChapterTabs 
          key={currentNode.id}
          currentNode={currentNode}
          setCurrentNode={setCurrentNode}
          chapterTreeId={normativeRecord.chapterTree!.id}
          handleModification={handleTreeModification}
        />
      </Grid>
    </Grid>
    ): <Loading />
  );
}
