import { ChipField, Datagrid, FunctionField, List, Loading, RaRecord, ReferenceArrayField, ReferenceField, SimpleList, SingleFieldList, TextField, useGetList, useListContext, WithListContext } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";
import { Instrument, Localization } from "../../../../shared/types/types";
import sacopModules from "../../../../shared/utilities/sacopModules";
import NoDataFound from "../../../../shared/components/noDataFound/NoDataFound";
import { Stack, Typography } from "@mui/material";

interface MobileGridProps {
    data?: RaRecord[];
}
const BookListView = () => {
    const { data } = useListContext<Localization>();
    return (
        <Stack spacing={2} sx={{ padding: 2 }}>
            {data.map(book => (
                <Typography key={book.id}>
                    <i>{book.title}</i>, by {book.id} ({book.name})
                </Typography>
            ))}
        </Stack>
    );
};

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<Instrument>();

    if (isLoading || data.length === 0) {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />;
    }

    return (
        <Datagrid bulkActionButtons={false}
        >
            <TextField label="resources.instrument.fields.name" source="name" variant="body1" />
            <TextField label="resources.instrument.fields.code" source="code" variant="body1" />
            <TextField label="resources.instrument.fields.brand" source="brand" variant="body1" />
            <TextField label="resources.instrument.fields.model" source="model" variant="body1" />
            <TextField label="resources.instrument.fields.verificationPeriod" source="calibration.verificationPeriod" variant="body1" />

            <ReferenceArrayField label="resources.instrument.fields.costCenter" source="costCenterId" reference="localization">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>

            <CoreEditButton permission="BACKOFFICE_INSTRUMENT_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_INSTRUMENT_D" />
        </Datagrid>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
