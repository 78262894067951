import { Fragment } from "react";
import {
  TextInput,
  FormDataConsumer,
  required,
  SelectInput,
} from "react-admin";
import { useWatch } from "react-hook-form";

import cities from "./cities";
import states from "./data";

const toChoices = (items) => {
  return items.map((item) => ({ id: item.id, label: item.label }));
};

export default function AddressInput() {
  const state = useWatch({ name: "address.state" });
  return (
    <>
      <SelectInput
        source="address.state"
        label="resources.company.fields.direction.state"
        fullWidth
        choices={toChoices(states)}
        validate={required()}
        optionValue="id"
        optionText="label"
      />
      <SelectInput
        source="address.colony"
        label="resources.company.fields.direction.colony"
        fullWidth
        validate={required()}
        choices={
          state
            ? toChoices(cities[state])
            : []
        }
        optionValue="id"
        optionText="label"
      />
      <TextInput
        source="address.street"
        label="resources.company.fields.direction.street"
        fullWidth
        validate={required()}
      />
    </>
  );
}
