import ProductIcon from '@mui/icons-material/Collections';
import RolList from './RolList';
import RolCreate from './RolCreate';
import RolEdit from './RolEdit';

export default {
    list: RolList,
    create: RolCreate,
    edit: RolEdit,
    icon: ProductIcon,
};
