import { defaultTheme } from "react-admin";
import image1 from "./image1.jpg";

export const darkTheme = {
  palette: {
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#FBBA72",
    },
    mode: "dark" as "dark", // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #000",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #90caf9",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#ffffffb3",
          backgroundColor: "#616161e6",
        },
      },
    },
  },
};

const palette = {
  type: "light",
  background: {
    default: "#F5F5F5",
    paper: "#fff",
  },
  primary: {
    main: "#56595C",
    light: "#94969A",
    contrastText: "#F5F5F5",
  },
  secondary: {
    main: "#e83f4b",
    light: "rgba(232, 63, 75, 0.8)",
  },
  text: {
    primary: "#56595C",
    secondary: "#94969A",
  },
};

export const lightTheme = {
  spacing: 8,
  palette,
  typography: {
    fontFamily: "Mukta",
    h1: {
      fontFamily: "Fira Sans",
      fontWeight: 500,
    },
    h2: {
      fontFamily: "Fira Sans",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Fira Sans",
    },
    h4: {
      fontFamily: "Fira Sans",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Fira Sans",
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Fira Sans",
    },
    subtitle1: {
      fontFamily: "Fira Sans",
    },
    subtitle2: {
      fontFamily: "Fira Sans",
    },
  },
  components: {
    ...defaultTheme.components,
    MuiAppBar: {
      color: "primary",
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,
          marginBot: "16px",
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-headerCell ": {
            backgroundColor: palette.primary.contrastText,
            fontFamily: "Fira Sans",
          },
          // '& .RaDatagrid-tbody': {
          //     backgroundImage: `url(${image1})`,
          //     backgroundSize: "cover",
          //     backgroundPosition: "center",
          // }
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator ": {
            backgroundColor: palette.secondary.main,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .Mui-checked": {
            color: palette.secondary.main,
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
            color: palette.secondary.main,
        // root: {
        //   "& .MuiToolbar-root>.MuiToolbar-root>*": {
        //     color: palette.secondary.main,
        //   },
        //   "& .MuiToolbar-root>.MuiToolbar-root>div>button": {
        //     color: palette.secondary.main,
        //   },
        // },
      },
    },
    RaDeleteWithUndoButton: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-contained": {
            backgroundColor: palette.secondary.main,
            color: palette.primary.contrastText,
          },
        },
      },
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          "& .RaToolbar-defaultToolbar": {
            m: 5,
            justifyContent: "start",
          },
          "& .RaToolbar-defaultToolbar .MuiButton-contained": {
            marginRight: "16px",
          },
        },
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          "& .RaSimpleFormIterator-line": {
            marginBottom: "24px",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
        fullWidth: true,
      },
      MuiFormControl: {
        defaultProps: {
          variant: "outlined" as const,
        },
      },
    },
  },
};
