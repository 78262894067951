import { useGetList } from "react-admin";

export default function useUser() {
  const {
    data: dataUser,
    total: totalUser,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useGetList("user", {
    filter: { active: true },
    pagination: { page: 1, perPage: 999 },
    sort: { field: "name", order: "DESC" },
  });

  return {
    dataUser,
    totalUser,
    isLoadingUser,
    errorUser,
  };
}
