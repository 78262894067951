import {
    TabbedForm,
    TextInput,
    BooleanInput,
    required,
    SelectInput,
    CheckboxGroupInput,
    DateInput,
    NumberInput,
    FileInput,
    FileField,
} from "react-admin";

export default function ExportForm() {
    return (
        <TabbedForm defaultValues={{ id: "0000-0000-0000-0000" }}>
            <TabbedForm.Tab label="resources.export.title" sx={{ maxWidth: "40em" }}>
                <TextInput
                    autoFocus
                    source="customsDus"
                    label="resources.export.fields.customs_dus"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="ahkDus"
                    label="resources.export.fields.ahk_dus"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="dusDate"
                    label="resources.export.fields.dus_date"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="dusItems"
                    label="resources.export.fields.dus_items"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="dusItemNumber"
                    label="resources.export.fields.dus_item_number"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="customHouse"
                    label="resources.export.fields.custom_house"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="weightReportNumber"
                    label="resources.export.fields.weight_report_number"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="weightReportDate"
                    label="resources.export.fields.weight_report_date"
                    fullWidth
                    validate={required()}
                />
                {/*<TextInput autoFocus source="" label="resources.export.fields.exporter" fullWidth validate={required()} /> */}
                <TextInput
                    autoFocus
                    source="remittee"
                    label="resources.export.fields.remittee"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="cochilcoContract"
                    label="resources.export.fields.cochilco_contract"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="contractFee"
                    label="resources.export.fields.contract_fee"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="commercialContract"
                    label="resources.export.fields.commercial_contract"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="exportPort"
                    label="resources.export.fields.export_port"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="destinationPort"
                    label="resources.export.fields.destination_port"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="motorShip"
                    label="resources.export.fields.motor_ship"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="testingLab"
                    label="resources.export.fields.testing_lab"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="testingLabReceptionDate"
                    label="resources.export.fields.testing_lab_reception_date"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="customLabReceptionDate"
                    label="resources.export.fields.custom_lab_reception_date"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="procedureCode"
                    label="resources.export.fields.procedure_code"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="weightMeasuringInstrument"
                    label="resources.export.fields.weight_measuring_instrument"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="weightSamplePlace"
                    label="resources.export.fields.weight_sample_place"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="samplingSystem"
                    label="resources.export.fields.sampling_system"
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    autoFocus
                    source="humiditySamplePlace"
                    label="resources.export.fields.humidity_sample_place"
                    fullWidth
                    validate={required()}
                />

                <DateInput
                    autoFocus
                    source="sampleStartDate"
                    label="resources.export.fields.sample_start_date"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="sampleEndDate"
                    label="resources.export.fields.sample_end_date"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="boardingStart"
                    label="resources.export.fields.boarding_start"
                    fullWidth
                    validate={required()}
                />
                <DateInput
                    autoFocus
                    source="boardingEnd"
                    label="resources.export.fields.boarding_end"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="grossWeight"
                    label="resources.export.fields.gross_weight"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="tareWeight"
                    label="resources.export.fields.tare_weight"
                    fullWidth
                />
                <NumberInput
                    autoFocus
                    source="dryNetWeight"
                    label="resources.export.fields.dry_net_weight"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="wetNetWeight"
                    label="resources.export.fields.wet_net_weight"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="humidityPercent"
                    label="resources.export.fields.humidity_percent"
                    fullWidth
                    validate={required()}
                />
                <NumberInput
                    autoFocus
                    source="totalGrossWeight"
                    label="resources.export.fields.total_gross_weight"
                    fullWidth
                    validate={required()}
                />
                <BooleanInput
                    autoFocus
                    source="active"
                    label="resources.export.fields.active"
                    fullWidth
                    validate={required()}
                />
            </TabbedForm.Tab>

            <TabbedForm.Tab
                label="resources.export.fields.dus_store"
                sx={{ maxWidth: "40em" }}
            ></TabbedForm.Tab>
            <TabbedForm.Tab
                label="resources.export.files"
                sx={{ maxWidth: "40em" }}
            >
                <FileInput
                    source=""
                    label="resources.export.fields.verification_list"
                >
                    <FileField source="src" title="title" />
                </FileInput>
                <FileInput
                    source=""
                    label="resources.export.fields.weight_report"
                >
                    <FileField source="src" title="title" />
                </FileInput>
                <FileInput source="" label="resources.export.fields.dus_copy">
                    <FileField source="src" title="title" />
                </FileInput>
                <FileInput
                    source=""
                    label="resources.export.fields.customs_delivery_letter"
                >
                    <FileField source="src" title="title" />
                </FileInput>
                <FileInput source="" label="resources.export.fields.other">
                    <FileField source="src" title="title" />
                </FileInput>
            </TabbedForm.Tab>
        </TabbedForm>
    );
}
