import * as React from 'react';
import {
    AppBar,
    TitlePortal,
    Logout,
    UserMenu,
    useTranslate,
    useUserMenu
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Theme,
    Typography,
    IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';

//import Logo from './Logo';
import logo from './logo_light.svg';

const SecurityMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    const { onClose } = useUserMenu();

    return (
        <MenuItem component={Link}
            // @ts-ignore
            ref={ref} {...props} to="/security" onClick={onClose}>
            
            <ListItemIcon>
                <SecurityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('resources.menu.security.name')}</ListItemText>
        </MenuItem>
    );
});

const SettingsButton = () => (
    <IconButton color="inherit">
        <SettingsIcon />
    </IconButton>
);

const CustomUserMenu = () => (
    <UserMenu>
        <SecurityMenu />
        <Logout />
    </UserMenu>
);

const CustomAppBar = () => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
      <AppBar elevation={1} userMenu={CustomUserMenu()}>
            <Typography
                flex="1"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                variant="h6"
                id="react-admin-title"
                sx={{color: "primary.contrastText"}}
            />
            <img src={logo} style={{width: "250px"}} alt="ahk-logo"/>
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    );
};

// {isLargeEnough && <Logo />} add line 57

export default CustomAppBar;
