import ProductIcon from '@mui/icons-material/Collections';
import InstrumentList from './InstrumentList';
import InstrumentCreate from './InstrumentCreate';
import InstrumentEdit from './InstrumentEdit';

export default {
    list: InstrumentList,
    create: InstrumentCreate,
    edit: InstrumentEdit,
    icon: ProductIcon,
};
