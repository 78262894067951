import { Edit, useRecordContext, useTranslate } from "react-admin";
import ExporterForm from "./ExporterForm";
import { Exporter } from "../../../../shared/types/types";
import {truncate} from "../../../../shared/utilities/truncate";

export default function ExporterCreate() {
  return(
      <Edit title={<ExporterTitle />}>
          <ExporterForm edit formTitle="editar exportador"/>
      </Edit>
  );
}

const ExporterTitle = () => {
    const exporterRecord = useRecordContext<Exporter>();
    const translate = useTranslate();
    return exporterRecord ? (
        <span>
            {`${translate("resources.exporter.title")} ${truncate(exporterRecord.name)}`}
        </span>
    ) : null;
};
