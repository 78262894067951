import { FileDownload } from "@mui/icons-material";
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";


export default function VersionTable({ versions }) {
  return (
    versions && (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell align="right">Fecha de Publicación</TableCell>
              <TableCell align="right">Archivo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.map((ver, i) => (
              <TableRow
                key={ver.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                {ver.fileInfo.idVersion}
                </TableCell>
                <TableCell align="right">{ver.publicationDate}</TableCell>
                <TableCell align="right">
                  {i === 0?
                    (<Link href={ver.fileInfo.src} target="_blank">
                      {ver.fileInfo.title}
                    </Link>) :
                      ver.fileInfo.title
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
