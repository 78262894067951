import { EditButton, useRecordContext } from "react-admin";
import { BtnSpecialProps } from "./BtnProps";
import validatePermissions from "../validatePermissions";
import sacopModules from "../../utilities/sacopModules";

const CoreEditButton = (props: BtnSpecialProps) => {
    const isValid = validatePermissions(props.permission)
    const record = useRecordContext(); 
    let buttonContent;

    switch (props.actionModule) {
        case sacopModules.REQUESTFORACTION:
            buttonContent = (
                isValid ? <EditButton {...props} disabled={record.sendToResponsibleForAnalysis} /> : null
            );
            break;
        case sacopModules.ANALYSISFORSHARES:
            buttonContent = (
                isValid ? <EditButton {...props} disabled={record.sendToResponsibleForImplementation} /> : null
            );
            break;
        case sacopModules.IMPLEMENTATIONFORACTION:
            buttonContent = (
                isValid ? <EditButton {...props} disabled={record.sendToResponsibleForClosingOfActions} /> : null
            );
            break;
        case sacopModules.CLOSINGFORSHARES:
            const validateClosing = Boolean(record.annulled || record.sendToResponsibleForVerificationEffectivenessAction);
            buttonContent = (
                isValid ? <EditButton {...props} disabled={validateClosing} /> : null
            );
            break;
        case sacopModules.VERIFICATIONEFFECTIVENESSACTION:
            const validateVerification = Boolean(record.annulled || record.rejected || record.approved);
            buttonContent = (
                isValid ? <EditButton {...props} disabled={validateVerification} /> : null
            );
            break;
        case sacopModules.MASTEROFSHARES:
            const validateMaster = Boolean(record.annulled || record.rejected);
            buttonContent = (
                isValid ? <EditButton {...props} disabled={validateMaster} /> : null
            );
            break;
        case sacopModules.NONE:
        default:
            buttonContent = (
                isValid ? <EditButton {...props} /> : null
            );
            break;
    }

    return buttonContent;
}

export default CoreEditButton;

