import { usePermissions } from "react-admin";

const validatePermissions = (permission: string) => {
    let isValid = false;
    const { permissions } = usePermissions();
    if (permissions) {
        permissions.forEach(permission1 => {
            if (isValid) {
                return;
            }

            if (permission1 == permission) {
                isValid = true;
            }
        })
    }
    return isValid;
}

export default validatePermissions;