import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import fetchDataService from "../../../../../shared/utilities/fetchs";
import { useNavigate } from "react-router-dom";

//19

interface RecoveryCodeProps {
    showSetup: any;
}

const CODE_LENGTH = 19;

const RecoveryCode = ({ showSetup } : RecoveryCodeProps) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const notify = useNotify();
    const { deleteMfaUsingRecoveryCode } = fetchDataService();
    const [showRecoveryCodeSetup, setShowRecoveryCodeSetup] = useState(false);
    const [recoveryCode, setRecoveryCode] = useState("")

    const sendRecoveryCode = () => {
        deleteMfaUsingRecoveryCode(recoveryCode)
        .then((response) => {
            notify(translate("resources.menu.security.mfa.fields.recovery_codes.use_code_success"), { type: 'success' });
            navigate('/#', { replace: true });
        })
        .catch((error) => {
            if (error.response.status === 401) {
                notify(translate("resources.menu.security.mfa.fields.recovery_codes.invalid_code"), { type: 'error' });
            } else {
                notify(translate("resources.menu.security.mfa.fields.notifications.error"), { type: 'error' });
            }
        });
    }

    return (
        <Stack width="100%" display="flex" alignItems="center" justifyContent="center" gap={1}>  
            {showRecoveryCodeSetup 
                ?   
                    <Stack display="flex" gap={4}>
                        <Typography variant="body1">
                            {translate("resources.menu.security.mfa.fields.recovery_codes.use_code")}
                        </Typography>
                        <TextField 
                            id="outlined-basic" 
                            label={translate("resources.menu.security.mfa.fields.recovery_codes.input_code")}
                            variant="outlined" 
                            onChange={(e) => setRecoveryCode(e.target.value)}
                        />
                        <Stack display="flex" flexDirection="row" justifyContent="end" gap={1}>
                            <Button variant="contained"
                                onClick={() => {
                                    showSetup();
                                    setShowRecoveryCodeSetup(false);
                                }}>
                                {translate("resources.menu.security.mfa.fields.btns.cancel_operation")}
                            </Button>
                            <Button variant="contained"
                                onClick={() => {
                                    sendRecoveryCode()
                                }}>
                                {translate("resources.menu.security.mfa.fields.btns.continue_operation")}
                            </Button>
                        </Stack>
                        
                    </Stack>
                :
                    <>
                        <Typography variant="body1">
                            {translate("resources.menu.security.mfa.fields.recovery_codes.question")}
                        </Typography>
                        <Button variant="contained" 
                            onClick={() => {
                                showSetup();
                                setShowRecoveryCodeSetup(true);
                            }}>
                            {translate("resources.menu.security.mfa.fields.recovery_codes.use_code_btn")}
                        </Button>
                    </>
            }
        </Stack>
    )
}

export default RecoveryCode;