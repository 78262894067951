import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Localization } from "../../../shared/types/types";
import LocalizationForm from "./LocalizationForm";

const LocalizationEdit = () => (
  <Edit
    title="Editar Centro de Costo"
    component="div"
  >
    <LocalizationForm formTitle="editar centro de costo" edit={true} />
  </Edit>
);

const LocalizationTitle = () => {
  const translate = useTranslate();
  const localizationRecord = useRecordContext<Localization>();
  return localizationRecord ? (
    <span>
      {`${translate("resources.localization.title")} ${
        localizationRecord.name
      }`}
    </span>
  ) : null;
};

export default LocalizationEdit;
