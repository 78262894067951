import { Edit, useRecordContext } from "react-admin";
import LaboratoryForm from "./LaboratoryForm";
import { LaboratoryRegistry } from "../../../shared/types/types";
import {truncate} from "../../../shared/utilities/truncate";

export default function LaboratoryRegistryEdit() {
    return (
        <Edit title={<span>Editar Registro de Laboratorio</span>}>
            <LaboratoryForm />
        </Edit>
    );
}

const LaboratoryRegistryTitle = () => {
    const labRegRecord = useRecordContext<LaboratoryRegistry>();
    return labRegRecord && labRegRecord.operationRegistry ? (
        <span>
            {`Registro ${truncate(labRegRecord.operationRegistry.verificationList)}, ${truncate(labRegRecord.operationRegistry.customHouse.name)}`}
        </span>
    ) : null;
};
