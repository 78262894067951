import { Create, TabbedForm } from "react-admin"
import ExportForm from "./ExportForm"
// import ExportForm from "./ExportForm"

export default function RegisterFormCreate() {
  return (
    <Create>
      <ExportForm />
    </Create>
  )
}
