import { Create } from "react-admin";
import InstrumentCreateForm from "./InstrumentCreateForm";

const InstrumentCreate = () => (
    <Create>
        <InstrumentCreateForm />
    </Create>
);

export default InstrumentCreate;
