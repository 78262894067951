import { Datagrid, DateField, NumberField, RaRecord, TextField } from "react-admin";
import sacopModules from "../../../../shared/utilities/sacopModules";
import CoreShowButton from "../../../../shared/components/core/CoreShowButton";


interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="resources.sacop.fields.id" variant="body1" />
            <TextField source="typeOfGeneratingEntity.nameOfGeneratingEntity" label="resources.sacop.fields.generator" variant="body1" sortable={false}/>
            <TextField source="motive.motiveName" label="resources.sacop.fields.motive.motiveName" variant="body1" sortable={false} />
            <DateField source="dateRequestAction" label="resources.sacop.fields.dateRequestAction" variant="body1" />
            <DateField source="dateOfFormationOfWorkTeam" label="resources.sacop.fields.analysisDate" variant="body1" />
            <DateField source="implementationDate" label="resources.sacop.fields.implementationDate" variant="body1" />
            <DateField source="closeDate" label="resources.sacop.fields.closeDate" variant="body1" />
            <DateField source="verificationDate" label="resources.sacop.fields.verificationDate" variant="body1" />
            <DateField source="expirationDateAction" label="resources.sacop.fields.expirationDateAction" variant="body1" />
            <NumberField source="valuationAmount" label="resources.sacop.fields.valuationAmount" variant="body1" />
            <TextField source="closingStatus.statusName" label="resources.sacop.fields.actionStatus" variant="body1" sortable={false}/>
            <TextField source="verificationStatus.statusName" label="resources.sacop.fields.verificationStatus" variant="body1" sortable={false}/>

            <CoreShowButton permission="BACKOFFICE_SHARES_COMMITEE_L" />
        </Datagrid>
    );
}

export default MobileGrid;