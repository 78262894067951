import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Stack, Typography } from "@mui/material";
import { getUserId } from "../../../../shared/utilities/local-storage-manager";
import MfaView from "./MFA/MfaView";

const SecurityView = () => {
    const currentUser = getUserId();

    return (
        <Box
            px={{ xs: 20, sm: 40 }}
            width="100%"
            display="flex"
            justifyContent="center"
            marginTop={4}
        >
            {/*
            <Stack spacing={1}>
                <Typography variant="h6">Modificar contraseña</Typography>
            </Stack>
            */}
            <Stack spacing={2}>
                <MfaView username={currentUser}/>
            </Stack>
        </Box>
    )
}

export default SecurityView;