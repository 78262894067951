import ProductIcon from '@mui/icons-material/Collections';
import RequestForActionCreate from './RequestForActionCreate';
import RequestForActionEdit from './RequestForActionEdit';
import RequestForActionList from './RequestForActionList';
import RequestForActionShow from './RequestForActionShow';

export default {
    list: RequestForActionList,
    create: RequestForActionCreate,
    edit: RequestForActionEdit,
    icon: ProductIcon,
    show: RequestForActionShow,
};