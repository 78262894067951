export default {
    AP: [{ id: "Arica", label: "Arica" }
        , { id: "Camarones", label: "Camarones" }
        , { id: "Putre", label: "Putre" }
        , { id: "General Lagos", label: "General Lagos" }
    ],
    TA: [{ id: "Iquique", label: "Iquique" }
        , { id: "Alto Hospicio", label: "Alto Hospicio" }
        , { id: "Pozo Almonte", label: "Pozo Almonte" }
        , { id: "Camiña", label: "Camiña" }
        , { id: "Colchane", label: "Colchane" }
        , { id: "Huara", label: "Huara" }
        , { id: "Pica", label: "Pica" }
    ]
    ,
    AN: [{ id: "Antofagasta", label: "Antofagasta" }
        , { id: "Mejillones", label: "Mejillones" }
        , { id: "Sierra Gorda", label: "Sierra Gorda" }
        , { id: "Taltal", label: "Taltal" }
        , { id: "Calama", label: "Calama" }
        , { id: "Ollagüe", label: "Ollagüe" }
        , { id: "San Pedro de Atacama", label: "San Pedro de Atacama" }
        , { id: "Tocopilla", label: "Tocopilla" }
        , { id: "María Elena", label: "María Elena" }
    ]
    ,
    AT: [{ id: "Copiapó", label: "Copiapó" }
        , { id: "Caldera", label: "Caldera" }
        , { id: "Tierra Amarilla", label: "Tierra Amarilla" }
        , { id: "Chañaral", label: "Chañaral" }
        , { id: "Diego de Almagro", label: "Diego de Almagro" }
        , { id: "Vallenar", label: "Vallenar" }
        , { id: "Alto del Carmen", label: "Alto del Carmen" }
        , { id: "Freirina", label: "Freirina" }
        , { id: "Huasco", label: "Huasco" }
    ]
    ,
    CO: [{ id: "La Serena", label: "La Serena" }
        , { id: "Coquimbo", label: "Coquimbo" }
        , { id: "Andacollo", label: "Andacollo" }
        , { id: "La Higuera", label: "La Higuera" }
        , { id: "Paiguano", label: "Paiguano" }
        , { id: "Vicuña", label: "Vicuña" }
        , { id: "Illapel", label: "Illapel" }
        , { id: "Canela", label: "Canela" }
        , { id: "Los Vilos", label: "Los Vilos" }
        , { id: "Salamanca", label: "Salamanca" }
        , { id: "Ovalle", label: "Ovalle" }
        , { id: "Combarbalá", label: "Combarbalá" }
        , { id: "Monte Patria", label: "Monte Patria" }
        , { id: "Punitaqui", label: "Punitaqui" }
        , { id: "Río Hurtado", label: "Río Hurtado" }
    ]
    ,
    VA: [{ id: "Valparaíso", label: "Valparaíso" }
        , { id: "Casablanca", label: "Casablanca" }
        , { id: "Concón", label: "Concón" }
        , { id: "Juan Fernández", label: "Juan Fernández" }
        , { id: "Puchuncaví", label: "Puchuncaví" }
        , { id: "Quintero", label: "Quintero" }
        , { id: "Viña del Mar", label: "Viña del Mar" }
        , { id: "Isla de Pascua", label: "Isla de Pascua" }
        , { id: "Los Andes", label: "Los Andes" }
        , { id: "Calle Larga", label: "Calle Larga" }
        , { id: "Rinconada", label: "Rinconada" }
        , { id: "San Esteban", label: "San Esteban" }
        , { id: "La Ligua", label: "La Ligua" }
        , { id: "Cabildo", label: "Cabildo" }
        , { id: "Papudo", label: "Papudo" }
        , { id: "Petorca", label: "Petorca" }
        , { id: "Zapallar", label: "Zapallar" }
        , { id: "Quillota", label: "Quillota" }
        , { id: "Calera", label: "Calera" }
        , { id: "Hijuelas", label: "Hijuelas" }
        , { id: "La Cruz", label: "La Cruz" }
        , { id: "Nogales", label: "Nogales" }
        , { id: "San Antonio", label: "San Antonio" }
        , { id: "Algarrobo", label: "Algarrobo" }
        , { id: "Cartagena", label: "Cartagena" }
        , { id: "El Quisco", label: "El Quisco" }
        , { id: "El Tabo", label: "El Tabo" }
        , { id: "Santo Domingo", label: "Santo Domingo" }
        , { id: "San Felipe", label: "San Felipe" }
        , { id: "Catemu", label: "Catemu" }
        , { id: "Llaillay", label: "Llaillay" }
        , { id: "Panquehue", label: "Panquehue" }
        , { id: "Putaendo", label: "Putaendo" }
        , { id: "Santa María", label: "Santa María" }
        , { id: "Quilpué", label: "Quilpué" }
        , { id: "Limache", label: "Limache" }
        , { id: "Olmué", label: "Olmué" }
        , { id: "Villa Alemana", label: "Villa Alemana" }
    ]
    ,
    RM: [{ id: "Cerrillos", label: "Cerrillos" }
        , { id: "Cerro Navia", label: "Cerro Navia" }
        , { id: "Conchalí", label: "Conchalí" }
        , { id: "El Bosque", label: "El Bosque" }
        , { id: "Estación Central", label: "Estación Central" }
        , { id: "Huechuraba", label: "Huechuraba" }
        , { id: "Independencia", label: "Independencia" }
        , { id: "La Cisterna", label: "La Cisterna" }
        , { id: "La Florida", label: "La Florida" }
        , { id: "La Granja", label: "La Granja" }
        , { id: "La Pintana", label: "La Pintana" }
        , { id: "La Reina", label: "La Reina" }
        , { id: "Las Condes", label: "Las Condes" }
        , { id: "Lo Barnechea", label: "Lo Barnechea" }
        , { id: "Lo Espejo", label: "Lo Espejo" }
        , { id: "Lo Prado", label: "Lo Prado" }
        , { id: "Macul", label: "Macul" }
        , { id: "Maipú", label: "Maipú" }
        , { id: "Ñuñoa", label: "Ñuñoa" }
        , { id: "Pedro Aguirre Cerda", label: "Pedro Aguirre Cerda" }
        , { id: "Peñalolén", label: "Peñalolén" }
        , { id: "Providencia", label: "Providencia" }
        , { id: "Pudahuel", label: "Pudahuel" }
        , { id: "Quilicura", label: "Quilicura" }
        , { id: "Quinta Normal", label: "Quinta Normal" }
        , { id: "Recoleta", label: "Recoleta" }
        , { id: "Renca", label: "Renca" }
        , { id: "Santiago", label: "Santiago" }
        , { id: "San Joaquín", label: "San Joaquín" }
        , { id: "San Miguel", label: "San Miguel" }
        , { id: "San Ramón", label: "San Ramón" }
        , { id: "Vitacura", label: "Vitacura" }
        , { id: "Puente Alto", label: "Puente Alto" }
        , { id: "Pirque", label: "Pirque" }
        , { id: "San José de Maipo", label: "San José de Maipo" }
        , { id: "Colina", label: "Colina" }
        , { id: "Lampa", label: "Lampa" }
        , { id: "Tiltil", label: "Tiltil" }
        , { id: "San Bernardo", label: "San Bernardo" }
        , { id: "Buin", label: "Buin" }
        , { id: "Calera de Tango", label: "Calera de Tango" }
        , { id: "Paine", label: "Paine" }
        , { id: "Melipilla", label: "Melipilla" }
        , { id: "Alhué", label: "Alhué" }
        , { id: "Curacaví", label: "Curacaví" }
        , { id: "María Pinto", label: "María Pinto" }
        , { id: "San Pedro", label: "San Pedro" }
        , { id: "Talagante", label: "Talagante" }
        , { id: "El Monte", label: "El Monte" }
        , { id: "Isla de Maipo", label: "Isla de Maipo" }
        , { id: "Padre Hurtado", label: "Padre Hurtado" }
        , { id: "Peñaflor", label: "Peñaflor" }
    ]
    ,
    LI: [{ id: "Rancagua", label: "Rancagua" }
        , { id: "Codegua", label: "Codegua" }
        , { id: "Coinco", label: "Coinco" }
        , { id: "Coltauco", label: "Coltauco" }
        , { id: "Doñihue", label: "Doñihue" }
        , { id: "Graneros", label: "Graneros" }
        , { id: "Las Cabras", label: "Las Cabras" }
        , { id: "Machalí", label: "Machalí" }
        , { id: "Malloa", label: "Malloa" }
        , { id: "Mostazal", label: "Mostazal" }
        , { id: "Olivar", label: "Olivar" }
        , { id: "Peumo", label: "Peumo" }
        , { id: "Pichidegua", label: "Pichidegua" }
        , { id: "Quinta de Tilcoco", label: "Quinta de Tilcoco" }
        , { id: "Rengo", label: "Rengo" }
        , { id: "Requínoa", label: "Requínoa" }
        , { id: "San Vicente", label: "San Vicente" }
        , { id: "Pichilemu", label: "Pichilemu" }
        , { id: "La Estrella", label: "La Estrella" }
        , { id: "Litueche", label: "Litueche" }
        , { id: "Marchihue", label: "Marchihue" }
        , { id: "Navidad", label: "Navidad" }
        , { id: "Paredones", label: "Paredones" }
        , { id: "San Fernando", label: "San Fernando" }
        , { id: "Chépica", label: "Chépica" }
        , { id: "Chimbarongo", label: "Chimbarongo" }
        , { id: "Lolol", label: "Lolol" }
        , { id: "Nancagua", label: "Nancagua" }
        , { id: "Palmilla", label: "Palmilla" }
        , { id: "Peralillo", label: "Peralillo" }
        , { id: "Placilla", label: "Placilla" }
        , { id: "Pumanque", label: "Pumanque" }
        , { id: "Santa Cruz", label: "Santa Cruz" }
    ]
    ,
    ML: [{ id: "Talca", label: "Talca" }
        , { id: "Constitución", label: "Constitución" }
        , { id: "Curepto", label: "Curepto" }
        , { id: "Empedrado", label: "Empedrado" }
        , { id: "Maule", label: "Maule" }
        , { id: "Pelarco", label: "Pelarco" }
        , { id: "Pencahue", label: "Pencahue" }
        , { id: "Río Claro", label: "Río Claro" }
        , { id: "San Clemente", label: "San Clemente" }
        , { id: "San Rafael", label: "San Rafael" }
        , { id: "Cauquenes", label: "Cauquenes" }
        , { id: "Chanco", label: "Chanco" }
        , { id: "Pelluhue", label: "Pelluhue" }
        , { id: "Curicó", label: "Curicó" }
        , { id: "Hualañé", label: "Hualañé" }
        , { id: "Licantén", label: "Licantén" }
        , { id: "Molina", label: "Molina" }
        , { id: "Rauco", label: "Rauco" }
        , { id: "Romeral", label: "Romeral" }
        , { id: "Sagrada Familia", label: "Sagrada Familia" }
        , { id: "Teno", label: "Teno" }
        , { id: "Vichuquén", label: "Vichuquén" }
        , { id: "Linares", label: "Linares" }
        , { id: "Colbún", label: "Colbún" }
        , { id: "Longaví", label: "Longaví" }
        , { id: "Parral", label: "Parral" }
        , { id: "Retiro", label: "Retiro" }
        , { id: "San Javier", label: "San Javier" }
        , { id: "Villa Alegre", label: "Villa Alegre" }
        , { id: "Yerbas Buenas", label: "Yerbas Buenas" }
    ]
    ,
    NB: [{ id: "Cobquecura", label: "Cobquecura" }
        , { id: "Coelemu", label: "Coelemu" }
        , { id: "Ninhue", label: "Ninhue" }
        , { id: "Portezuelo", label: "Portezuelo" }
        , { id: "Quirihue", label: "Quirihue" }
        , { id: "Ránquil", label: "Ránquil" }
        , { id: "Treguaco", label: "Treguaco" }
        , { id: "Bulnes", label: "Bulnes" }
        , { id: "Chillán Viejo", label: "Chillán Viejo" }
        , { id: "Chillán", label: "Chillán" }
        , { id: "El Carmen", label: "El Carmen" }
        , { id: "Pemuco", label: "Pemuco" }
        , { id: "Pinto", label: "Pinto" }
        , { id: "Quillón", label: "Quillón" }
        , { id: "San Ignacio", label: "San Ignacio" }
        , { id: "Yungay", label: "Yungay" }
        , { id: "Coihueco", label: "Coihueco" }
        , { id: "Ñiquén", label: "Ñiquén" }
        , { id: "San Carlos", label: "San Carlos" }
        , { id: "San Fabián", label: "San Fabián" }
        , { id: "San Nicolás", label: "San Nicolás" }
    ]
    ,
    BI: [{ id: "Concepción", label: "Concepción" }
        , { id: "Coronel", label: "Coronel" }
        , { id: "Chiguayante", label: "Chiguayante" }
        , { id: "Florida", label: "Florida" }
        , { id: "Hualqui", label: "Hualqui" }
        , { id: "Lota", label: "Lota" }
        , { id: "Penco", label: "Penco" }
        , { id: "San Pedro de la Paz", label: "San Pedro de la Paz" }
        , { id: "Santa Juana", label: "Santa Juana" }
        , { id: "Talcahuano", label: "Talcahuano" }
        , { id: "Tomé", label: "Tomé" }
        , { id: "Hualpén", label: "Hualpén" }
        , { id: "Lebu", label: "Lebu" }
        , { id: "Arauco", label: "Arauco" }
        , { id: "Cañete", label: "Cañete" }
        , { id: "Contulmo", label: "Contulmo" }
        , { id: "Curanilahue", label: "Curanilahue" }
        , { id: "Los Álamos", label: "Los Álamos" }
        , { id: "Tirúa", label: "Tirúa" }
        , { id: "Los Ángeles", label: "Los Ángeles" }
        , { id: "Antuco", label: "Antuco" }
        , { id: "Cabrero", label: "Cabrero" }
        , { id: "Laja", label: "Laja" }
        , { id: "Mulchén", label: "Mulchén" }
        , { id: "Nacimiento", label: "Nacimiento" }
        , { id: "Negrete", label: "Negrete" }
        , { id: "Quilaco", label: "Quilaco" }
        , { id: "Quilleco", label: "Quilleco" }
        , { id: "San Rosendo", label: "San Rosendo" }
        , { id: "Santa Bárbara", label: "Santa Bárbara" }
        , { id: "Tucapel", label: "Tucapel" }
        , { id: "Yumbel", label: "Yumbel" }
        , { id: "Alto Biobío", label: "Alto Biobío" }
    ]
    ,
    AR: [{ id: "Temuco", label: "Temuco" }
        , { id: "Carahue", label: "Carahue" }
        , { id: "Cunco", label: "Cunco" }
        , { id: "Curarrehue", label: "Curarrehue" }
        , { id: "Freire", label: "Freire" }
        , { id: "Galvarino", label: "Galvarino" }
        , { id: "Gorbea", label: "Gorbea" }
        , { id: "Lautaro", label: "Lautaro" }
        , { id: "Loncoche", label: "Loncoche" }
        , { id: "Melipeuco", label: "Melipeuco" }
        , { id: "Nueva Imperial", label: "Nueva Imperial" }
        , { id: "Padre las Casas", label: "Padre las Casas" }
        , { id: "Perquenco", label: "Perquenco" }
        , { id: "Pitrufquén", label: "Pitrufquén" }
        , { id: "Pucón", label: "Pucón" }
        , { id: "Saavedra", label: "Saavedra" }
        , { id: "Teodoro Schmidt", label: "Teodoro Schmidt" }
        , { id: "Toltén", label: "Toltén" }
        , { id: "Vilcún", label: "Vilcún" }
        , { id: "Villarrica", label: "Villarrica" }
        , { id: "Cholchol", label: "Cholchol" }
        , { id: "Angol", label: "Angol" }
        , { id: "Collipulli", label: "Collipulli" }
        , { id: "Curacautín", label: "Curacautín" }
        , { id: "Ercilla", label: "Ercilla" }
        , { id: "Lonquimay", label: "Lonquimay" }
        , { id: "Los Sauces", label: "Los Sauces" }
        , { id: "Lumaco", label: "Lumaco" }
        , { id: "Purén", label: "Purén" }
        , { id: "Renaico", label: "Renaico" }
        , { id: "Traiguén", label: "Traiguén" }
        , { id: "Victoria", label: "Victoria" }
    ]
    ,
    LR: [{ id: "Valdivia", label: "Valdivia" }
        , { id: "Corral", label: "Corral" }
        , { id: "Lanco", label: "Lanco" }
        , { id: "Los Lagos", label: "Los Lagos" }
        , { id: "Máfil", label: "Máfil" }
        , { id: "Mariquina", label: "Mariquina" }
        , { id: "Paillaco", label: "Paillaco" }
        , { id: "Panguipulli", label: "Panguipulli" }
        , { id: "La Unión", label: "La Unión" }
        , { id: "Futrono", label: "Futrono" }
        , { id: "Lago Ranco", label: "Lago Ranco" }
        , { id: "Río Bueno", label: "Río Bueno" }
    ]
    ,
    LL: [{ id: "Puerto Montt", label: "Puerto Montt" }
        , { id: "Calbuco", label: "Calbuco" }
        , { id: "Cochamó", label: "Cochamó" }
        , { id: "Fresia", label: "Fresia" }
        , { id: "Frutillar", label: "Frutillar" }
        , { id: "Los Muermos", label: "Los Muermos" }
        , { id: "Llanquihue", label: "Llanquihue" }
        , { id: "Maullín", label: "Maullín" }
        , { id: "Puerto Varas", label: "Puerto Varas" }
        , { id: "Castro", label: "Castro" }
        , { id: "Ancud", label: "Ancud" }
        , { id: "Chonchi", label: "Chonchi" }
        , { id: "Curaco de Vélez", label: "Curaco de Vélez" }
        , { id: "Dalcahue", label: "Dalcahue" }
        , { id: "Puqueldón", label: "Puqueldón" }
        , { id: "Queilén", label: "Queilén" }
        , { id: "Quellón", label: "Quellón" }
        , { id: "Quemchi", label: "Quemchi" }
        , { id: "Quinchao", label: "Quinchao" }
        , { id: "Osorno", label: "Osorno" }
        , { id: "Puerto Octay", label: "Puerto Octay" }
        , { id: "Purranque", label: "Purranque" }
        , { id: "Puyehue", label: "Puyehue" }
        , { id: "Río Negro", label: "Río Negro" }
        , { id: "San Juan de la Costa", label: "San Juan de la Costa" }
        , { id: "San Pablo", label: "San Pablo" }
        , { id: "Chaitén", label: "Chaitén" }
        , { id: "Futaleufú", label: "Futaleufú" }
        , { id: "Hualaihué", label: "Hualaihué" }
        , { id: "Palena", label: "Palena" }
    ]
    ,
    AI: [{ id: "Coihaique", label: "Coihaique" }
        , { id: "Lago Verde", label: "Lago Verde" }
        , { id: "Aisén", label: "Aisén" }
        , { id: "Cisnes", label: "Cisnes" }
        , { id: "Guaitecas", label: "Guaitecas" }
        , { id: "Cochrane", label: "Cochrane" }
        , { id: "O’Higgins", label: "O’Higgins" }
        , { id: "Tortel", label: "Tortel" }
        , { id: "Chile Chico", label: "Chile Chico" }
        , { id: "Río Ibáñez", label: "Río Ibáñez" }
    ]
    ,
    MA: [{ id: "Punta Arenas", label: "Punta Arenas" }
        , { id: "Laguna Blanca", label: "Laguna Blanca" }
        , { id: "Río Verde", label: "Río Verde" }
        , { id: "San Gregorio", label: "San Gregorio" }
        , { id: "Cabo de Hornos (Ex Navarino)", label: "Cabo de Hornos (Ex Navarino)" }
        , { id: "Antártica", label: "Antártica" }
        , { id: "Porvenir", label: "Porvenir" }
        , { id: "Primavera", label: "Primavera" }
        , { id: "Timaukel", label: "Timaukel" }
        , { id: "Natales", label: "Natales" }
        , { id: "Torres del Paine", label: "Torres del Paine" }
    ]
}