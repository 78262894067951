import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Sacop } from "../../../../shared/types/types";
import ClosingOfSharesForm from "./ClosingOfSharesForm";

export default function ClosingOfSharesEdit() {
  return(
      <Edit title={<ClosingOfSharesTitle />}>
          <ClosingOfSharesForm formTitle={<ActionEditTitle />} labelEvidence={<LabelEvidence />} edit={true}/>
      </Edit>
  );
}

const ClosingOfSharesTitle = () => {
    const sacopRecord = useRecordContext<Sacop>();
    const translate = useTranslate();
    return sacopRecord ? (
        <span>
            {`${translate("resources.sacop.titles.closingOfShares.title")}`}
        </span>
    ) : null;
};

const ActionEditTitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.actions.closingOfShares.edit.title")}`}
        </span>
    );
};

const LabelEvidence = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.fields.evidence")}`}
        </span>
    );
};