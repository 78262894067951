import { CreateButton } from "react-admin";
import { ExtendedCreateBtnProps } from "./BtnProps";
import validatePermissions from "../validatePermissions";

const CoreCreateButton = (props: ExtendedCreateBtnProps) => {
    const isValid = validatePermissions(props.permission)
    return (
        isValid ? <CreateButton {...props} /> : null
    );
}

export default CoreCreateButton;

