import { ShowButton } from "react-admin";
import { BtnProps } from "./BtnProps";
import validatePermissions from "../validatePermissions";

const CoreShowButton = (props: BtnProps) => {
    const isValid = validatePermissions(props.permission)
    return (
        isValid ? <ShowButton /> : null
    );
} 

export default CoreShowButton;

