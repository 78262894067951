import { Theme, useMediaQuery } from "@mui/material";
import { Fragment, useCallback } from "react";
import { TextField, DatagridConfigurable, List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, CreateButton, SortButton, useGetResourceLabel, TextInput, BooleanInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_LOCALIZATION_C" />
        <SortButton fields={['name']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const localizationFilters = [
    <SearchInput source="q" alwaysOn />,

    <BooleanInput source="active" />,
    <TextInput source="code" />,
    <TextInput source="name" />

];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="rol" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}

const LocalizationList = () => {
    return (<List
        filterDefaultValues={ { active: true }}
        sort={{ field: 'name', order: 'DESC' }}
        perPage={25} 
        filters={localizationFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default LocalizationList;
