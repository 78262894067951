import PersonIcon from '@mui/icons-material/Person';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const UsersWithoutDocumentation = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/user"
            icon={PersonIcon}
            title={translate('resources.dashboard.usersWithoutDocumentation')}
            subtitle={value}
        />
    );
};

export default UsersWithoutDocumentation;