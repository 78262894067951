import { Edit, useRecordContext, useTranslate } from "react-admin";
import LaboratoryForm from "./LaboratoryForm";
import { Laboratory } from "../../../../shared/types/types";
import {truncate} from "../../../../shared/utilities/truncate";

export default function LaboratoryCreate() {
  return(
      <Edit title={<LaboratoryTitle />}>
          <LaboratoryForm formTitle="editar laboratorio" edit={true}/>
      </Edit>
  );
}

const LaboratoryTitle = () => {
    const laboratoryRecord = useRecordContext<Laboratory>();
    const translate = useTranslate();
    return laboratoryRecord ? (
        <span>
            {`${translate("resources.laboratory.title")} ${truncate(laboratoryRecord.name)}`}
        </span>
    ) : null;
};
