import ProductIcon from '@mui/icons-material/Collections';
import AnalysisOfSharesList from './AnalysisOfSharesList';
import AnalysisOfSharesEdit from './AnalysisOfSharesEdit';
import AnalysisOfSharesShow from './analysisOfSharesShow';

export default {
    list: AnalysisOfSharesList,
    edit: AnalysisOfSharesEdit,
    icon: ProductIcon,
    show: AnalysisOfSharesShow,
};