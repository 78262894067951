import { ArrayInput, number, NumberInput, SimpleFormIterator } from "react-admin";

export default function StoresInput() {
  return(
    <ArrayInput
      source="stores"
      label="resources.weight_report_operation.fields.stores.name"
    >
      <SimpleFormIterator inline>
        <NumberInput
          source="number"
          label="resources.weight_report_operation.fields.stores.number"
          variant="outlined"
          helperText={false}
          validate={number()}
        />
        <NumberInput
          source="wetNetWeight"
          label="resources.weight_report_operation.fields.stores.wet_net_weight"
          validate={number()}
          variant="outlined"
          helperText={false}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
}
