import { CloudUpload } from "@mui/icons-material";
import { Box, Button, ButtonProps, Typography, styled } from "@mui/material";
import { ChangeEvent } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface UploadFileButtonProps extends ButtonProps {
  file?: File;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  innerText: string;
}

export default function UploadFileButton({
  file,
  handleChange,
  innerText,
  ...rest
}: UploadFileButtonProps) {
  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Button
        sx={{mr: 1}}
        {...rest}
        startIcon={<CloudUpload />}
      >
        {innerText}
        <VisuallyHiddenInput type="file" onChange={(e) => handleChange(e)} />
      </Button>
      {file && <Typography variant="body2">{file.name}</Typography>}
    </Box>
  );
}
