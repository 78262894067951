import {
  ArrayInput,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  required,
  CheckboxGroupInput,
  BooleanInput,
  SimpleForm,
} from "react-admin";

import module from "./data/module";
import permission from "./data/permission";
import { Fragment } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Divider, Typography } from "@mui/material";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";

const toChoices = (items) => {
  return items.map((item) => ({ id: item.id, name: item.name }));
};

interface SectorFormProps {
  edit?: boolean;
  title: string
}

const SectorForm = ({ edit, title }: SectorFormProps) => (
  <SimpleForm toolbar={<FormToolbar edit={edit? true : undefined} />} sx={{mt: 3}}>
    <Grid container sx={{ width: "100%" }}>
      <Grid container xl={8} xlOffset={2} lg={10} lgOffset={1} spacing={1}>
        <Grid xs={12}>
          <Typography variant="h6">{title.toUpperCase()}</Typography>
        </Grid>
        <Grid container xs={12}>
        <Grid xs={12} lg={8}>
          <TextInput
            source="name"
            label="resources.rol.fields.name"
            fullWidth
            variant="outlined"
            validate={required()}
            helperText={false}
          />
        </Grid>
        </Grid>
        <Grid container xs={12}>
        <Grid xs={12} lg={8} >
          <TextInput
            source="description"
            label="resources.rol.fields.description"
            fullWidth
            variant="outlined"
            multiline
            validate={required()}
            helperText={false}
          />
        </Grid>
        </Grid>
        <Grid md={2}>
          <Box>
            <BooleanInput
              source="active"
              label="resources.rol.fields.active"
              validate={required()}
              helperText={false}
            />
          </Box>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">Permisos</Typography>
        </Grid>
        <Grid xs={8} >
          <ArrayInput label="" source="permissions">
            <SimpleFormIterator
              inline
              disableReordering
            >
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => (
                  <Fragment>
                    <SelectInput
                      autoFocus
                      source={getSource?.("module")}
                      label="resources.rol.fields.permission.module"
                      choices={toChoices(module)}
                      validate={required()}
                      translateChoice={true}
                      helperText={false}
                      variant="outlined"
                    />

                    <CheckboxGroupInput
                      source={getSource?.("permission")}
                      helperText={false}
                      label="resources.rol.fields.permission.name"
                      validate={required()}
                      choices={
                        scopedFormData && scopedFormData.module
                          ? toChoices(permission[scopedFormData.module])
                          : []
                      }
                      translateChoice={true}
                    />
                  </Fragment>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </Grid>
  </SimpleForm>
);

export default SectorForm;
