import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import fetchDataService from "../../utilities/fetchs";
import UploadFileButton from "../buttons/UploadFileButton";
import RegistriesTable from "./RegistriesTable";
import { useNotify } from "react-admin";

export default function Registries({
  registries,
  currentNode,
  setCurrentNode,
  handleModification,
}) {
  const [file, setFile] = useState<File | undefined>();
  const [confirmButton, setConfirmButton] = useState();
  const notify = useNotify();

  const handleConfirmation = async (e) => {
    const { postChapterRegistryData } = fetchDataService();
    await postChapterRegistryData({
      chapterId: currentNode.id,
      document: file,
    });
    handleModification();
    setCurrentNode({
      id: "",
      name: "",
      code: "",
      type: "",
      active: false,
      childrens: undefined,
    });
    notify("Registro creado", {
      type: "info",
    });
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h6" color="primary">
          Registros
        </Typography>
        <UploadFileButton
          file={file}
          size="small"
          component="label"
          variant="outlined"
          handleChange={(e) => e.target.files && setFile(e.target.files[0])}
          innerText="Adjuntar Registro"
        />

        {file && (
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="contained"
              onClick={handleConfirmation}
            >
              Registrar
            </Button>
            <Button
              size="small"
              variant="text"
              onClick={(e) => setFile(undefined)}
            >
              Cancelar
            </Button>
          </Stack>
        )}

        <RegistriesTable registries={[...registries].reverse()} />
      </Stack>
    </>
  );
}
