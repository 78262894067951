import {
  TabbedForm,
  TextInput,
  required,
  useGetList,
  Loading,
  SelectInput,
  DateInput,
  BooleanInput,
} from "react-admin";
import OperationForm from "../../../../shared/components/operationRegistry/OperationForm";
import WeightReportInput from "./inputs/WeightReportInput";
import BoardingInput from "./inputs/BoardingInput";
import ContractInput from "./inputs/ContractInput";
import ProcedureInput from "./inputs/ProcedureInput";
import StoresInput from "./inputs/StoresInput";
import MoreDataTab from "./MoreDataTab";
import { Laboratory, Port } from "../../../../shared/types/types";
import OtherFilesInput from "./inputs/OtherFilesInput";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Typography } from "@mui/material";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";

interface weightReportOperationProps {
  edit?: boolean;
}

const WeightReportOperationForm = ({ edit }: weightReportOperationProps) => {
  const { data: laboratoryData, isLoading: laboratoryIsLoading } =
    useGetList<Laboratory>("laboratory", {
      sort: { field: "name", order: "DESC" },
      pagination: { page: 1, perPage: 999 },
      filter: {active: true},
    });

  const { data: portData, isLoading: portIsLoading } = useGetList<Port>(
    "port",
    {
      filter: {active: true},
      sort: { field: "name", order: "DESC" },
      pagination: { page: 1, perPage: 999 },
    }
  );

  if (laboratoryIsLoading || portIsLoading) return <Loading />;

  return (
    <TabbedForm toolbar={<FormToolbar edit={edit ? true : undefined} />}>
      <TabbedForm.Tab label="Operación">
        <Grid container sx={{ width: "100%", mb: 3, mt: 1 }}>
          <Grid container lg={10} lgOffset={1} spacing={2}>
            <Typography sx={{ ml: 1 }} variant="h6">
              REGISTRO DE EXPORTACIÓN
            </Typography>
          </Grid>
        </Grid>

        <OperationForm />

        <Grid container sx={{ width: "100%"}}>
          <Grid container lg={10} lgOffset={1} spacing={2}>
            <Grid xs={12} md={3}>
              <TextInput
                source="verificationList"
                label="resources.weight_report_operation.fields.verification_list"
                disabled
                variant="outlined"
                helperText={false}
                fullWidth
                // validate={required()}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <SelectInput
                source="testingLab"
                label="resources.weight_report_operation.fields.testing_lab"
                fullWidth
                variant="outlined"
                helperText={false}
                choices={laboratoryData}
              />
            </Grid>
            <Grid xs={12} md={2}>
              <DateInput
                source="customLabReceptionDate"
                fullWidth
                variant="outlined"
                helperText={false}
                label="resources.weight_report_operation.fields.custom_lab_reception_date"
              />
            </Grid>
            <Grid xs={12} md={4}>
              <SelectInput
                fullWidth
                helperText={false}
                source="exportPort"
                label="resources.weight_report_operation.fields.export_port"
                variant="outlined"
                choices={portData}
                validate={required()}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <TextInput
                fullWidth
                helperText={false}
                variant="outlined"
                source="destinationPort"
                label="resources.weight_report_operation.fields.destination_port"
              />
            </Grid>
            <Grid xs={12} md={3}>
              <TextInput
                source="cochilcoContract.number"
                label="resources.weight_report_operation.fields.cochilco_contract.number"
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <TextInput
                source="cochilcoContract.fee"
                label="resources.weight_report_operation.fields.cochilco_contract.fee"
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <TextInput
                source="commercialContract.number"
                fullWidth
                variant="outlined"
                helperText={false}
                label="resources.weight_report_operation.fields.commercial_contract.number"
              />
            </Grid>
            <Grid xs={12} md={2}>
              <DateInput
                fullWidth
                variant="outlined"
                helperText={false}
                source="boardingStart"
                label="resources.weight_report_operation.fields.boarding_start"
              />
            </Grid>
            <Grid xs={12} md={2}>
              <DateInput
                fullWidth
                variant="outlined"
                helperText={false}
                source="boardingEnd"
                label="resources.weight_report_operation.fields.boarding_end"
              />
            </Grid>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Procedimiento">
        <ProcedureInput />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Informe de Peso">
        <WeightReportInput />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Otros Archivos">
        <OtherFilesInput />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default WeightReportOperationForm;
