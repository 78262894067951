import { BooleanField, Datagrid, RaRecord, TextField } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";
import sacopModules from "../../../../shared/utilities/sacopModules";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="resources.exporter.fields.name"  variant="body1" />
            <TextField source="taxpayerReference" label="resources.exporter.fields.taxpayer_reference" variant="body1" />
            <TextField source="contactEmail" label="resources.exporter.fields.contact_email" variant="body1" />
            <TextField source="contactPhone" label="resources.exporter.fields.contact_phone" variant="body1" />
            <TextField source="ahkContactId" label="resources.exporter.fields.ahk_contact" variant="body1" />
            <BooleanField source="active" />
            <CoreEditButton permission="BACKOFFICE_EXPORTER_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_EXPORTER_D" />
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
