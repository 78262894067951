import { Create } from "react-admin";
import UserForm from "./UserForm";

const UserCreate = () => (
    <Create>
        <UserForm formTitle="Crear Usuario"/>
    </Create>
);

export default UserCreate;
