import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Instrument } from "../../../../shared/types/types";
import InstrumentEditForm from "./InstrumentEditForm";
import {truncate} from "../../../../shared/utilities/truncate";


const InstrumentEdit = () => (
    <Edit title={<InstrumentTitle />} component="div">
        <InstrumentEditForm />
    </Edit>
);

const InstrumentTitle = () => {
    const translate = useTranslate();
    const instrumentRecord = useRecordContext<Instrument>();
    return instrumentRecord ? (
        <span>
            {`${translate("resources.instrument.title")} ${truncate(instrumentRecord.name)} ${truncate(instrumentRecord.code)}`}
        </span>
    ) : null;
};

export default InstrumentEdit;
