import { Create } from "react-admin";
import LocalizationForm from "./LocalizationForm";

const LocalizationCreate = () => (
    <Create
      title="Crear Centro de Costo"
    >
        <LocalizationForm formTitle="crear centro de costo" />
    </Create>
);

export default LocalizationCreate;
