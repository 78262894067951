import { Create, useRecordContext } from "react-admin";
import { SamplingLocation } from "../../../../shared/types/types";
import { truncate } from "../../../../shared/utilities/truncate";
import SamplingLocationForm from "./SamplingLocationForm";

const SamplingLocationCreate = () => (
  <Create title={<span> Crear Lugar de Toma de Muestra </span>}>
    <SamplingLocationForm formTitle="crear lugar de toma de muestra" />
  </Create>
);

export default SamplingLocationCreate;
