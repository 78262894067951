import {
  BooleanField,
  Datagrid,
  EmailField,
  Loading,
  RaRecord,
  TextField,
  useListContext,
  useTranslate,
} from "react-admin";
import { CustomsHouse } from "../../../../shared/types/types";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";
import sacopModules from "../../../../shared/utilities/sacopModules";
import NoDataFound from "../../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
  data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {
  const { data, isLoading } = useListContext<CustomsHouse>();
  const translate = useTranslate();
  if (isLoading || data.length === 0) {
    if (data?.length === 0)
      return <NoDataFound />
    return <Loading />;
  }

  return (
    <Datagrid bulkActionButtons={false}>
      <TextField
        source="name"
        label="resources.customs_house.fields.name"
        variant="body1"
      />
      <TextField
        source="contactName"
        label="resources.customs_house.fields.contact_name"
        variant="body1"
      />
      <TextField
        source="contactPhone"
        label="resources.customs_house.fields.contact_phone"
        variant="body1"
      />
      <TextField
        source="contactEmail"
        label="resources.customs_house.fields.contact_email"
        variant="body1"
      />
      <TextField
        source="ahkContactId"
        label="resources.customs_house.fields.ahk_contact"
        variant="body1"
      />
      <TextField
        sortable={false}
        source="address.street"
        label="resources.customs_house.fields.address"
        variant="body1"
      />
      <BooleanField
        source="active"
        label="resources.customs_house.fields.active"
        variant="body1"
      />
      
      <CoreEditButton permission="BACKOFFICE_CUSTOMS_C" actionModule={sacopModules.NONE} />
      <CoreDeleteButton permission="BACKOFFICE_CUSTOMS_U" />
    </Datagrid>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
