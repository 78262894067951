import { DeleteButton } from "react-admin";
import { BtnProps } from "./BtnProps";
import validatePermissions from "../validatePermissions";

const CoreDeleteButton = (props: BtnProps) => {
    const isValid = validatePermissions(props.permission)
    return (
        isValid ? <DeleteButton {...props} /> : null
    );
}

export default CoreDeleteButton;

