import { Cancel, Close, CloudUpload, Save } from "@mui/icons-material";
import Folder from "@mui/icons-material/Folder";
import {
  Alert,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import fetchDataService from "../../utilities/fetchs";
import UploadFileButton from "../buttons/UploadFileButton";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface InputData {
  name?: string;
  docFile?: Blob;
  code?: string;
  type?: string;
  active?: boolean;
  inputData?: string
  code_version?: string
}

export default function ChapterModalForm({
  open,
  setOpen,
  chapterTreeId,
  currentNode,
  handleTreeChange,
}) {
  const [error, setError] = useState(false);
  const [file, setFile] = useState<File>();
  const [inputData, setInputData] = useState<InputData>({
    name: "",
    code: "",
    active: false,
    docFile: undefined,
    type: "",
    code_version: ""
  });
  const notify = useNotify();
  const translate = useTranslate();

  const handleClose = () => {
    setInputData({
      name: "",
      code: "",
      active: false,
      docFile: undefined,
      type: "",
    })
    setFile(undefined);
    setOpen(false);
  };

  const handleSave = () => {
    if (inputData.name == "" || inputData.code == "" || inputData.type == "") {
      setError(true);
      return
    }

    const { postMultipartChapterTreeData } = fetchDataService();

    const chapterTreeInfo = {
      id: chapterTreeId,
      root: {
        ...inputData,
        childrens: inputData.type === "d" ? [] : undefined,
      },
      chapterNodeId: currentNode.id,
    };

    postMultipartChapterTreeData(chapterTreeId, {
      chapterTreeInfo,
      docFile: file,
    }).then((res) => {
      handleTreeChange();
      notify(translate("ra.notification.created", { smart_count: 1 }), {
        type: "info",
      });
    });
  };

  return (
    <div>
      <Dialog open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle>Nuevo Capítulo</DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1}>
            <Grid xs={12} md={6}>
              <TextField
                label="Carpeta Padre"
                id="folder-textfield"
                fullWidth
                disabled
                value={currentNode.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Folder />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                value={inputData.name}
                required
                label="Nombre"
                onChange={(e) => {
                  setInputData({ ...inputData, name: e.target.value });
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                value={inputData.code}
                required
                label="Código"
                onChange={(e) => {
                  setInputData({ ...inputData, code: e.target.value });
                }}
              />
            </Grid>

            <Grid xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="type-select-small-label">Tipo</InputLabel>
                <Select
                  labelId="type-select-small-label"
                  id="type-select-small"
                  label="Tipo"
                  required
                  value={inputData.type}
                  variant="outlined"
                  onChange={(e) => {
                    setInputData({ ...inputData, type: e.target.value });
                  }}
                >
                  <MenuItem value="d">Carpeta</MenuItem>
                  <MenuItem value="f">Documento</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {inputData.type === "f" && (
              <>
                <Grid xs={12}>
                  <UploadFileButton
                    file={file}
                    variant="contained"
                    component="label"
                    handleChange={(e) =>
                      e.target.files && setFile(e.target.files[0])
                    }
                    innerText="Subir Versión"
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    value={inputData.code_version}
                    required
                    label="Versión"
                    onChange={(e) => {
                      setInputData({ ...inputData, code_version: e.target.value });
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid xs={12}>
              <FormGroup sx={{width: 100}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={inputData.active}
                      onChange={(e) =>
                        setInputData({ ...inputData, active: e.target.checked })
                      }
                    />
                  }
                  label="Activo"
                />
              </FormGroup>
            </Grid>
          </Grid>
          { error && <Alert severity="error">Por favor, llene los campos requeridos</Alert>}
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Save />} variant="contained" onClick={handleSave}>
            Guardar
          </Button>
          <Button
            startIcon={<Cancel />}
            variant="text"
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
