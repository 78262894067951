import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  DateInput,
  FileField,
  FileInput,
  required,
  TextInput,
} from "react-admin";

export default function WeightReportInput() {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid container lg={10} lgOffset={1} spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6">
            INFORME DE PESO
          </Typography>
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="weightReport.number"
            fullWidth
            helperText={false}
            variant="outlined"
            disabled
            label="resources.weight_report_operation.fields.weight_report.number"
          />
        </Grid>
        <Grid xs={12} md={2}>
          <DateInput
            fullWidth
            helperText={false}
            variant="outlined"
            source="weightReport.date"
            label="resources.weight_report_operation.fields.weight_report.date"
          />
        </Grid>
        <Grid xs={12} md={8}>
          <FileInput
            source="weightReport.file"
            label="resources.weight_report_operation.fields.weight_report.file"
            helperText={false}
            fullWidth
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </Grid>
    </Grid>
  );
}
