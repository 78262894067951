import { Edit, useRecordContext, useTranslate } from "react-admin";
import { WeightReportOperation } from "../../../../shared/types/types";
import WeightReportOperationForm from "./WeightReportOperationForm";

const WeightReportOperationEdit = () => (
    <Edit title={<WeightReportOperationTitle />} component="div">
        <WeightReportOperationForm edit={true}/>
    </Edit>
);

const WeightReportOperationTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext<WeightReportOperation>();
    return record ? (
        <span>
            {`Registro de Exportación ${record.verificationList}`}
        </span>
    ) : null;
};


export default WeightReportOperationEdit;
