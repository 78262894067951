import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import fetchDataService from "../../../../../shared/utilities/fetchs";
import MfaCodeSetup from "./MfaCodeSetup";
import { useNavigate } from "react-router-dom";
import { useNotify, useTranslate } from "react-admin";

const SetupMfa = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const notify = useNotify();
    const { postSetupMfa } = fetchDataService();
    const [isLoading, setIsLoading] = useState(true);
    const [setupData, setSetupData] = useState<any>(null);
    const [showValidateCode, setShowValidateCode] = useState(false);

    useEffect(() => {
        postSetupMfa().then((result) => {
            setSetupData(result.data.data);
        })
        .catch((error) => {
            console.error(`ERROR => ${error}`)
            notify(translate("resources.menu.security.mfa.fields.notifications.error"), { type: 'error' });
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [])

    const initSetup = () => {
        return (
            <>
                <Typography variant="h6">{translate("resources.menu.security.mfa.name")}</Typography>
                    <Typography variant="body1">
                        {translate("resources.menu.security.mfa.fields.setup.init")}
                    </Typography>
                    <Typography variant="body1">
                        <strong>1.</strong> {translate("resources.menu.security.mfa.fields.setup.step_one")}
                    </Typography>

                    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Stack alignItems="center">
                            <Typography variant="body1">
                                <strong>Android</strong>
                            </Typography>
                            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
                                <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">{translate("resources.menu.security.mfa.fields.setup.google_auth")}</Link>
                                <Link href="https://play.google.com/store/apps/details?id=com.azure.authenticator">{translate("resources.menu.security.mfa.fields.setup.microsoft_auth")}</Link>
                                <Link href="https://play.google.com/store/apps/details?id=com.authy.authy">{translate("resources.menu.security.mfa.fields.setup.authy_auth")}</Link>
                            </Stack>
                        </Stack>
                        <Stack alignItems="center">
                            <Typography variant="body1">
                                <strong>iOS</strong>
                            </Typography>
                            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
                                <Link href="https://apps.apple.com/es/app/google-authenticator/id388497605">{translate("resources.menu.security.mfa.fields.setup.google_auth")}</Link>
                                <Link href="https://apps.apple.com/es/app/microsoft-authenticator/id983156458">{translate("resources.menu.security.mfa.fields.setup.microsoft_auth")}</Link>
                                <Link href="https://apps.apple.com/es/app/authy/id494168017">{translate("resources.menu.security.mfa.fields.setup.authy_auth")}</Link>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Typography variant="body1">
                        <strong>2.</strong> {translate("resources.menu.security.mfa.fields.setup.step_two")}
                    </Typography>
                    
                    <Stack justifyContent="center" alignItems="center">
                        <img
                            src={setupData?.qrDataUri}
                            alt="qr setup code"
                            style={{ width: 300, height: 300 }}
                        />
                    </Stack>

                    <Typography variant="caption">
                        {translate("resources.menu.security.mfa.fields.setup.code_alternative")} <strong>{setupData?.secretKey}</strong>
                    </Typography>
                    <Typography variant="body1">
                        <strong>3.</strong> {translate("resources.menu.security.mfa.fields.setup.step_three")}
                    </Typography>
                    <Stack display="flex" flexDirection="row" justifyContent="end" gap={2}>
                        <Button variant="contained" onClick={() => navigate('/security', { replace: true })}>{translate("resources.menu.security.mfa.fields.btns.cancel_operation")}</Button>
                        <Button variant="contained" onClick={() => setShowValidateCode(true)} color="secondary">{translate("resources.menu.security.mfa.fields.btns.continue_operation")}</Button>
                    </Stack>
            </>
        )
    }

    return (
        <>
            <Box
                marginTop={3}
                px={{ xs: 1, sm: 4 }}
                width="100%"
                display="flex"
                justifyContent="center"
            >
                <Stack spacing={2} justifyContent="center">
                    {showValidateCode ? 
                        <MfaCodeSetup 
                            initSetup 
                            show/>
                        :
                        initSetup()
                    }
                </Stack>
            </Box>
            
        </>
    )
}

export default SetupMfa;