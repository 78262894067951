import { Delete } from "@mui/icons-material";
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function RegistriesTable({ registries }) {
  return (
    registries && (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Archivo</TableCell>
              <TableCell align="left">Fecha de Publicación</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registries.map((reg) => (
              <TableRow key={reg.id}>
                <TableCell component="th" scope="row">
                  <Link href={reg.document.src} target="_blank">
                    {reg.document.title}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {reg.publicationDate}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button startIcon={<Delete />} variant="text" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
