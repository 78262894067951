import jwt_decode from "jwt-decode";
import { Token, Data } from "../types/token.type";

export const saveInLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
};

export const getInLocalStorageAsJson = (key: string) => {
    const result = localStorage.getItem(key);
    return !!result && JSON.parse(result);
};
export const getInLocalStorageAsString = (key: string) => {
    const result = localStorage.getItem(key) ?? '';
    return result;
};
export const clearLocalStorage = () => {
    localStorage.clear();
};

export const getOrgId = () => {
    const payload: Token = jwt_decode(getInLocalStorageAsString('auth'))
    const value = (JSON.parse(payload?.data) as Data).Claims?.Orgs[0].OrgId
    return value
}

export const getFullName = () => {
    const payload: Token = jwt_decode(getInLocalStorageAsString('auth'))
    const value = `${payload.firstName} ${payload.lastName}`
    return value
}

export const getAvatar = () => {
    const payload: Token = jwt_decode(getInLocalStorageAsString('auth'))
    const value = payload.avatar
    return value
}

export const getOrgPermissions = () => {
    const payload: Token = jwt_decode(getInLocalStorageAsString('auth'))
    const value = (JSON.parse(payload?.data) as Data).Claims?.Orgs[0].Permissions
    return value
}

export const getUserId = () => {
    const payload: Token = jwt_decode(getInLocalStorageAsString('auth'))
    const value = payload.sub
    return value
}