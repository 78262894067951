import ProductIcon from '@mui/icons-material/Collections';
import SamplingLocationList from './SamplingLocationList';
import SamplingLocationCreate from './SamplingLocationCreate';
import SamplingLocationEdit from './SamplingLocationEdit';

export default {
    list: SamplingLocationList,
    create: SamplingLocationCreate,
    edit: SamplingLocationEdit,
    icon: ProductIcon,
};
