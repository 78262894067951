import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import {
    List,
    SearchInput,
    useListContext,
    TopToolbar,
    FilterButton,
    ExportButton,
    SortButton,
    useGetResourceLabel,
    TextInput,
    useTranslate,
    BooleanInput,
} from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_CUSTOMS_C" />
        <SortButton fields={["name"]} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const templateFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="name" />,
    <BooleanInput source="active" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down("sm")
    );
    return <MobileGrid {...listContext} />;
};

const CustomsHouseList = () => {
    const getResourceLabel = useGetResourceLabel();
    return (
        <List
            filterDefaultValues={ { active: true }}
            sort={{ field: "name", order: "DESC" }}
            perPage={25}
            filters={templateFilters}
            actions={<ListActions />}
            title="Lista de Aduanas"
        >
            <TabbedDatagrid />
        </List>
    );
};

export default CustomsHouseList;
