import { Create } from "react-admin";
import CustomsHouseForm from "./CustomsForm";

const CustomsHouseCreate = () => (
    <Create>
        <CustomsHouseForm formTitle="crear aduana" />
    </Create>
);

export default CustomsHouseCreate;
