import { Create, useTranslate } from "react-admin";
import RequestForActionForm from "./RequestForActionForm";

export default function RequestForActionCreate() {
  return(
    <Create>
      <RequestForActionForm formTitle={<ActionCreateTitle />}/>
    </Create>
  );
}

const ActionCreateTitle = () => {
  const translate = useTranslate();
  return (
    <span>
        {`${translate("resources.sacop.actions.requestForAction.create.title")}`}
    </span>
  );
};