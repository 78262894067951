import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  TabbedForm,
  TextInput,
  BooleanInput,
  required,
  SelectInput,
  CheckboxGroupInput,
  DateInput,
  NumberInput,
  FileInput,
  FileField,
  useGetList,
  Loading,
  AutocompleteInput,
} from "react-admin";

export default function OperationForm() {
  const { data: customsHouses, isLoading: customsHousesIsLoading } = useGetList(
    "customsHouse",
    {
      filter: {active: true},
      sort: { field: "name", order: "DESC" },
      pagination: { page: 1, perPage: 999 },
    }
  );

  const { data: exporters, isLoading: exportersIsLoading } = useGetList(
    "exporter",
    {
      filter: {active: true},
      sort: { field: "name", order: "DESC" },
      pagination: { page: 1, perPage: 999 },
    }
  );

  if (customsHousesIsLoading || exportersIsLoading) {
    return <Loading />;
  }

  return (
    <Grid container sx={{ width: "100%", mb: 2}}>
    <Grid container lg={10} lgOffset={1} spacing={2}>
      <Grid xs={12} md={3}>
        <TextInput
          source="operationRegistry.dus"
          label="resources.operation_registry.fields.dus"
          variant="outlined"
          helperText={false}
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={2}>
        <DateInput
          source="operationRegistry.dusDate"
          label="resources.operation_registry.fields.dus_date"
          variant="outlined"
          validate={required()}
          helperText={false}
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={2.3}>
        <NumberInput
          source="operationRegistry.itemCount"
          label="resources.operation_registry.fields.dus_items"
          variant="outlined"
          helperText={false}
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={1.7}>
        <NumberInput
          source="operationRegistry.itemNumber"
          label="resources.operation_registry.fields.dus_item_number"
          variant="outlined"
          helperText={false}
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <TextInput
          source="operationRegistry.motorship"
          label="resources.operation_registry.fields.motorship"
          helperText={false}
          variant="outlined"
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={3}>
          <AutocompleteInput
            source="operationRegistry.customHouse.id"
            label="resources.operation_registry.fields.custom_house"
            choices={
              customsHouses
                ? customsHouses.map((user) => ({
                    id: user.id,
                    name: user.name,
                  }))
                : []
            }
            fullWidth
          />
      </Grid>
      <Grid xs={12} md={3}>
      <AutocompleteInput
            source="operationRegistry.exporter.id"
            label="resources.operation_registry.fields.exporter"
            choices={
              exporters
                ? exporters.map((user) => ({
                    id: user.id,
                    name: user.name,
                  }))
                : []
            }
            fullWidth
          />
      </Grid>
      <Grid xs={12} md={2}>
        <DateInput
          source="operationRegistry.samplingStartDate"
          label="resources.operation_registry.fields.sample_start_date"
          variant="outlined"
          helperText={false}
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={2}>
        <DateInput
          source="operationRegistry.samplingEndDate"
          label="resources.operation_registry.fields.sample_end_date"
          variant="outlined"
          helperText={false}
          fullWidth
          // validate={required()}
        />
      </Grid>
      <Grid xs={12} md={2}>
        <DateInput
          source="operationRegistry.testingLabReceptionDate"
          label="resources.operation_registry.fields.testing_lab_reception_date"
          fullWidth
          variant="outlined"
          helperText={false}
          // validate={required()}
        />
      </Grid>
    </Grid>
    </Grid>
  );
}
