import ProductIcon from '@mui/icons-material/Collections';
import PortList from './PortList';
import PortCreate from './PortCreate';
import PortEdit from './PortEdit';

export default {
    list: PortList,
    create: PortCreate,
    edit: PortEdit,
    icon: ProductIcon,
};
