import { Fragment } from "react";
import { TextInputProps, TextInput, regex, required } from "react-admin";

const PhoneInput = (props: TextInputProps) => {
  return (
    <Fragment>
      <TextInput
        {...props}
        validate={[
          required(),
          // regex(/^(\+?56)?(9[0-9]{8})$/, "Número de teléfono inválido"),
        ]}
      />
    </Fragment>
  );
};

export default PhoneInput;
