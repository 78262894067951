import { useGetList } from "react-admin";
import { SamplingLocation } from "../types/types";

export default function useSamplingLocation() {
  const { data: samplingLocData, isLoading: samplingLocIsLoading } = useGetList<SamplingLocation>(
    "samplingLocation",
    {
      sort: { field: "name", order: "DESC" },
      filter: { active: true }
    }
  );
  return({
    samplingLocData,
    samplingLocIsLoading,
  })
}
