import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Role } from "../../../../shared/types/types";
import RolForm from "./RolForm";

const RolEdit = () => (
  <Edit title={<RoleTitle />} component="div">
    <RolForm title="editar rol" edit={true} />
  </Edit>
);

const RoleTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<Role>();
  return record ? (
    <span>
      {translate("resources.commands.title", {
        module: 'Rol',
        reference: record.name,
      })}
    </span>
  ) : null;
};

export default RolEdit;

