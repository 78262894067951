import { Show, useRecordContext, useTranslate } from "react-admin";
import { Sacop } from "../../../../shared/types/types";
import ShowSacopDetailForm from "../../../../shared/components/sacopDetail/showSacopDetail";

export default function ImplementationOfActionShow() {
    return(
        <Show title={<ImplementationForActionTitle />}>
            <ShowSacopDetailForm 
                show={true}
                formTitle={<ActionShowTitle />}
                firstSection={<ActionShowFirthSubtitle />}
                secondSection={<ActionShowSecondSubtitle />}
                thirdSection={<ActionShowThirdSubtitle />}
                fourthSection={<ActionShowFourthSubtitle />}
                fifthSection={<ActionShowFifthSubtitle />}
                labelEvidence={<LabelEvidence />}
                labelImpact={<LabelImpact />}
                labelWorkTeamMembers={<LabelWorkTeamMembers />}
            />
        </Show>
    );
}

const ImplementationForActionTitle = () => {
    const sacopRecord = useRecordContext<Sacop>();
    const translate = useTranslate();
    return sacopRecord ? (
        <span>
            {`${translate("resources.sacop.titles.requestForAction.title")}`}
        </span>
    ) : null;
};

const ActionShowTitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.titles.detail.title")}`}
        </span>
    );
};

const ActionShowFirthSubtitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.subtitles.firstSection.title")}`}
        </span>
    );
};

const ActionShowSecondSubtitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.subtitles.secondSection.title")}`}
        </span>
    );
};

const ActionShowThirdSubtitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.subtitles.thirdSection.title")}`}
        </span>
    );
};

const ActionShowFourthSubtitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.subtitles.fourthSection.title")}`}
        </span>
    );
};

const ActionShowFifthSubtitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.subtitles.fifthSection.title")}`}
        </span>
    );
};

const LabelEvidence = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.fields.evidence")}`}
        </span>
    );
};

const LabelImpact = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.fields.impactId")}`}
        </span>
    );
};

const LabelWorkTeamMembers = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.fields.workTeamMembers")}`}
        </span>
    );
};