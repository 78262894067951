import { Create } from "react-admin";
import LaboratoryForm from "./LaboratoryForm";

export default function LaboratoryCreate() {
  return(
    <Create>
      <LaboratoryForm formTitle="crear laboratorio" />
    </Create>
  );
}
