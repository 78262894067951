import { useEffect, useState } from "react";
import fetchDataService from "../../../../../shared/utilities/fetchs";
import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../../shared/components/LoadingSpinner";
import { useNotify, useTranslate } from 'react-admin';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { saveInLocalStorage } from "../../../../../shared/utilities/local-storage-manager";

export interface MfaProps {
    username: string
}

const MfaView = ({ username } : MfaProps) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const notify = useNotify();
    const { getUserInfo, deleteMfa } = fetchDataService();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmDisableModal, setConfirmDisableModal] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [userPassword, setUserPassword] = useState('');

    const onDisableMfa = () => {

        if (userPassword.length === 0) {
            notify(translate("resources.menu.security.mfa.fields.notifications.password_request"), { type: 'info' })
            return;
        }

        setIsLoading(true);
        deleteMfa(userPassword).then((response) => {
            saveInLocalStorage('auth', response.data.data.accessToken);
            notify(translate("resources.menu.security.mfa.fields.notifications.deactivate_success"), { type: 'success' });
            setConfirmDisableModal(false);
            setUser({
                ...user,
                isMfaActive: false
            })
        })
        .catch((error) => {
            if (error.response.status === 401) {
                notify(translate("resources.menu.security.mfa.fields.notifications.invalid_credentials"), { type: 'error' });
            } else {
                notify(translate("resources.menu.security.mfa.fields.notifications.error"), { type: 'error' });
            }
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getUserInfo(username).then((result) => {
            setUser(result.data.data);
        })
        .catch((error) => {
            console.error(`ERROR => ${error}`);
            notify(translate("resources.menu.security.mfa.fields.notifications.error"), { type: 'error' });
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [])

    return (
        <>
            {isLoading 
                ?
                    <LoadingSpinner/>
                :
                <Stack spacing={2} justifyContent="center" marginTop={4}>
                    <Stack display="flex" flexDirection="row" alignItems="center" columnGap={1}>
                        <Typography variant="h6">{translate("resources.menu.security.mfa.name")}</Typography>
                        {user.isMfaActive
                            ?
                                <CheckCircleIcon/>
                            :
                                <DangerousIcon/>
                        }   
                    </Stack>
                    <Stack spacing={2}>
                        {user && 
                            user.isMfaActive 
                            ? 
                                <>  
                                    {confirmDisableModal 
                                        ?
                                            <>
                                                <Typography variant="body1">
                                                    {translate("resources.menu.security.mfa.fields.confirm_delete_description")}
                                                </Typography>
                                                <TextField 
                                                    type="password"
                                                    label={translate('ra.auth.password')}
                                                    variant="outlined"
                                                    placeholder="*******"
                                                    value={userPassword}
                                                    onChange={(e) => setUserPassword(e.target.value)}
                                                />
                                                <Stack display="flex" flexDirection="row" justifyContent="end" gap={1}>
                                                    <Button 
                                                        variant="contained" 
                                                        onClick={() => {
                                                            setUserPassword("")
                                                            setConfirmDisableModal(false)
                                                        }}>
                                                            {translate("resources.menu.security.mfa.fields.btns.cancel_operation")}
                                                        </Button>
                                                    <Button variant="contained" onClick={() => onDisableMfa()}>{translate("resources.menu.security.mfa.fields.btns.confirm_operation")}</Button>
                                                </Stack>
                                                
                                            </>
                                        :
                                        <>
                                            <Typography variant="body1">
                                                {translate("resources.menu.security.mfa.fields.activated_description")}
                                            </Typography>
                                            <Button variant="contained" onClick={() => setConfirmDisableModal(true)}>{translate("resources.menu.security.mfa.fields.btns.deactivate_mfa")}</Button>
                                        </>
                                    }
                                </>
                            :   
                                <>
                                    <Typography variant="body1">
                                        {translate("resources.menu.security.mfa.fields.not_activated_description")}
                                    </Typography>
                                    <Button variant="contained" onClick={() => navigate('setup-mfa')}>{translate("resources.menu.security.mfa.fields.btns.activate_mfa")}</Button>
                                </>
                        }
                    </Stack> 
                </Stack>
            }
        </>
    );
}

export default MfaView;